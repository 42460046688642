<template>
    <div @click="editing = !editing && editable"
         class="grid grid-cols-todo-details outline-none
                py-2 px-1 narrow:p-0
                border-2 border-transparent rounded-lg
                focus:border-gray-400 focus:dark:border-gray-500"
         :class="{ 'cursor-pointer': editable }"
         :tabindex="editable ? 0 : -1"
         @keydown.space.prevent="editing = editable && true"
         @keydown.enter.prevent="editing = editable && true"
         ref="field"
    >
        <span id="todo-labels" class="py-1 text-blue-600 dark:text-blue-300 font-bold">Labels</span>
        <span v-if="!editing" class="pl-2 max-w-full">
            <span v-if="labels.length === 0" class="inline-block py-1">None</span>
            <span v-for="label in labels"
                  :key="label"
                  class="inline-block text-white rounded-xl px-2 py-1 mr-2 mb-2 max-w-full"
                  :style="{ backgroundColor: labelByUuid(label).color }"
            >
                {{ labelByUuid(label).name }}
            </span>
        </span>
    </div>

    <form v-if="editing"
          class="mb-2"
          @submit.prevent="save"
          @keydown.esc.prevent="editing = false; nextTick(() => field.focus())"
    >
        <!-- https://www.w3.org/TR/2017/WD-wai-aria-practices-1.1-20170628/examples/listbox/listbox.html -->
        <div class="inline-flex flex-col
                w-96 narrow:w-full
                border-gray-400 dark:border-gray-500 border-2 focus-within:border-blue-500
                rounded">
            <todo-labels
                    aria-labelledby="todo-labels"
                    :availableLabels="store.state.settings.labels"
                    :selectedLabels="selectedLabels"
            ></todo-labels>

            <div class="flex border-inherit">
                <submit-button class="flex-grow border-0"></submit-button>

                <button @click="clear"
                        type="button"
                        class="flex-grow
                            hover:bg-blue-200 dark:hover:bg-blue-400
                            border-inherit border-l-2
                            px-2 py-1"
                >
                    Clear
                </button>

                <button @click="editing = false"
                        type="button"
                        class="flex-grow
                            hover:bg-blue-200 dark:hover:bg-blue-400
                            border-inherit border-l-2
                            px-2 py-1"
                >
                    Cancel
                </button>
            </div>
        </div>
    </form>
</template>

<script setup>
import { nextTick, ref, toRefs, watch } from "vue";
import { useStore } from 'vuex';

import SubmitButton from "@/components/form/SubmitButton";
import TodoLabels from "@/components/TodoLabels";

const store = useStore();

const emit = defineEmits(['changed']);

const props = defineProps({
  close: {
    type: Boolean,
    required: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  labels: {
    type: Array,
    required: true,
  },
});

const { close, editable, labels } = toRefs(props);

const editing = ref(false);
const field = ref(null);
const selectedLabels = ref([ ...labels.value ]);

const labelByUuid = (uuid) => {
  return store.getters.labelByUuid(uuid);
};

const clear = () => {
  selectedLabels.value = [];
};

const save = () => {
  emit('changed', selectedLabels.value);
  editing.value = false;
};

watch(editing, (currentValue, oldValue) => {
    if (currentValue) {
      nextTick(() => {
        selectedLabels.value = [...labels.value];
      });
    }
});

watch(close, (currentValue) => {
  if (currentValue) {
    editing.value = false;
  }
});
</script>