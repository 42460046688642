<template>
    <div class="flex flex-col w-full content-between h-full overflow-auto">
        <todo-name :name="todo.name"
                   :editable="todo.status === NOT_DONE"
                   :close="close"
                   @changed="(name) => renameTodo({ uuid: todo.uuid, name})"
        ></todo-name>

        <hr/>

        <div>
            <todo-due :dueAt="todo.dueAt"
                      :editable="todo.status === NOT_DONE"
                      :close="close"
                      @changed="(dueAt) => changeTodoDueDate({ uuid: todo.uuid, dueAt})"
            ></todo-due>

            <todo-repeat :repeat="todo.repeat"
                         :editable="todo.status === NOT_DONE"
                         :close="close"
                         @changed="(repeat) => changeTodoRepeatFrequency({ uuid: todo.uuid, repeat })"
            ></todo-repeat>

            <todo-labels-form :labels="todo.labels"
                        :editable="todo.status === NOT_DONE"
                         :close="close"
                        @changed="(labels) => changeTodoLabels({ uuid: todo.uuid, labels })"
            ></todo-labels-form>
        </div>

        <hr v-if="todo.status === NOT_DONE || todo.description !== ''"/>

        <todo-description v-if="todo.status === NOT_DONE || todo.description !== ''"
                          :description="todo.description"
                          :close="close"
                          @changed="(description) => changeTodoDescription({uuid: todo.uuid, description})"
        ></todo-description>

        <div class="flex-grow py-2"></div>

        <div v-if="todo.doneAt === 0"
             class="flex flex-grow-0 justify-between w-full"
        >
            <button v-if="todo.uuid === currentTodo?.uuid"
                    @click="postponeCurrentTodo"
                    class="border-2 border-gray-400 px-2 py-1 hover:bg-blue-400 rounded"
            >
                Do it later
            </button>

            <button v-if="todo.uuid !== currentTodo?.uuid"
                    @click="markTodoAsCurrent(todo.uuid)"
                    class="border-2 border-gray-400 px-2 py-1 hover:bg-blue-400 rounded"
            >
                Do this now
            </button>

            <button v-if="todo.uuid !== currentTodo?.uuid"
                    @click="router.push({name: 'Todo'})"
                    class="border-2 border-gray-400 px-2 py-1 hover:bg-blue-400 rounded"
            >
                Back to current
            </button>

            <button v-if="todo.doneAt === 0"
                    @click="markTodoAsDone(todo.uuid)"
                    class="border-2 border-blue-600 dark:border-blue-400
                        hover:bg-blue-200 dark:bg-gray-800
                        px-2 py-1 rounded
                        text-blue-600 dark:text-blue-400 dark:hover:text-blue-600
                        font-bold"
            >

                <tick alt=""
                      class="inline w-4 mr-1 mb-1"
                      fill="currentColor"
                      stroke="currentColor"
                />Cross it off
            </button>
        </div>

        <div v-if="todo.doneAt > 0"
             class="flex flex-grow-0 justify-between w-full"
        >
            <button v-if="todo.repeat.never"
                    @click="copy"
                    class="border-2 border-gray-400 px-2 py-1 hover:bg-blue-400 rounded"
            >
                Do this again
            </button>

            <button @click="router.push({name: 'Todo'})"
                    class="border-2 border-gray-400 px-2 py-1 hover:bg-blue-400 rounded"
            >
                Back to current
            </button>

            <button disabled class="text-green-700 dark:text-green-400 px-2 py-1">
                <tick alt=""
                      class="inline w-4 mr-1 mb-1"
                      fill="currentColor"
                      stroke="currentColor"
                />Done
            </button>
        </div>
    </div>
</template>


<script setup>
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import { useHead } from '@vueuse/head';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { NOT_DONE } from '@/store/todo-statuses';
import {ymd} from '@/utils/date';

import Tick from '@/components/ui/Tick';
import TodoDescription from '@/components/TodoDescription';
import TodoDue from '@/components/TodoDue';
import TodoLabelsForm from '@/components/TodoLabelsForm';
import TodoName from '@/components/TodoName';
import TodoRepeat from '@/components/TodoRepeat';

import ActionTypes from '@/store/action-types';

import {
  NO_MORE_TODOS
} from '@/store/errors';

const route = useRoute();
const router = useRouter();
const store = useStore();

const close = ref(false);
const editingTodoName = ref(false);
const error = ref('');
const showNoMoreTodos = ref(false);

const currentTodo = computed(() => store.getters.currentTodo);
const lastChangedAt = computed(() => store.getters.lastChangedAt);
const thingsToBeDoneToday = computed(() => store.getters.thingsToBeDoneToday);

const todo = computed(() => {
  if (route.params.uuid) {
    return store.getters.todoByUuid(route.params.uuid);
  }

  return currentTodo.value;
});

const changeTodoDueDate = (params) => store.dispatch(ActionTypes.CHANGE_TODO_DUE_DATE, params);
const changeTodoDescription = (params) => store.dispatch(ActionTypes.CHANGE_TODO_DESCRIPTION, params);
const changeTodoLabels = (params) => store.dispatch(ActionTypes.CHANGE_TODO_LABELS, params);
const changeTodoRepeatFrequency = (params) => store.dispatch(ActionTypes.CHANGE_TODO_REPEAT_FREQUENCY, params);
const renameTodo = (params) => store.dispatch(ActionTypes.RENAME_TODO, params);

const markTodoAsDone = (uuid) => {
  store.dispatch(ActionTypes.MARK_TODO_AS_DONE, uuid);

  nextTick(() => {
    if (currentTodo.value?.uuid === null) {
      router.push({name: 'AllDone'});
      return;
    }

    router.push({name: 'Todo'});
  });
};

const markTodoAsCurrent = (uuid) => {
  store.dispatch(ActionTypes.MARK_TODO_AS_CURRENT, uuid);
};

const postponeCurrentTodo = () => {
  try {
    store.dispatch(ActionTypes.POSTPONE_CURRENT_TODO);
    nextTick(() => {
      router.push({name: 'Todo'});
    });
  } catch (e) {
    if (e.message === NO_MORE_TODOS) {
      store.dispatch(ActionTypes.PUSH_MESSAGE, {
        message: 'This is the last thing to do on your list.',
      });

      return;
    }

    throw e;
  }
};

const updateLastChangedAt = () => store.dispatch(ActionTypes.UPDATE_LAST_CHANGED_AT);

const copy = async () => {
  const uuid = await store.dispatch(ActionTypes.COPY_TODO, todo.value.uuid);

  await router.push({name: 'Todo', params: { uuid }});
}

watch(todo, () => {
  close.value = true;
  nextTick(() => {
    close.value = false;
  });
});

onMounted(() => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (
    todo.value.uuid === currentTodo.value.uuid
    && ymd(lastChangedAt.value) < ymd(yesterday)
    && thingsToBeDoneToday.value.length === 0
  ) {
    store.dispatch(ActionTypes.PUSH_MESSAGE, {
      message: 'Looks like you\'ve been doing this for a couple of days. If you\'re feeling stuck, try something else for a break.',
      callback: updateLastChangedAt
    });
  }

  if (
    todo.value.uuid === currentTodo.value.uuid
    && ymd(todo.value.dueAt) !== ymd(today)
    && ymd(lastChangedAt.value) <= ymd(yesterday)
    && thingsToBeDoneToday.value.length > 0
  ) {
    const nextUuid = thingsToBeDoneToday.value[0].uuid;

    store.dispatch(ActionTypes.PUSH_MESSAGE, {
      message: 'Looks like you have other things in your list that are due today. If you want to switch to one of those, you can do this one later.',
      callback: updateLastChangedAt,
      actions: [
        {
          text: 'Switch',
          title: 'Switch to today\'s to-dos',
          callback: async () => {
            await store.dispatch(ActionTypes.MARK_TODO_AS_CURRENT, nextUuid);
            await router.push({name: 'Todo', params: { uuid: nextUuid }});
          }
        },
      ],
    });
  }
});

useHead({
  title: computed(() => `${todo?.value?.name ?? 'Todo'} | Cross It Off`),
});

</script>
