import ActionTypes from "@/store/action-types";
import MutationTypes from "@/store/mutation-types";

import {
  NO_MORE_TODOS
} from "@/store/errors";
import {NOT_DONE} from "@/store/todo-statuses";
import {v4 as uuid4} from "uuid";
import {addMonthsToDate} from "@/utils/date";

export const actions = {
  [ActionTypes.ADD_TODO]: ({commit, dispatch, state}, details) => {
    const uuid = uuid4();

    const todo = {
      uuid,
      name: '',
      description: '',
      status: NOT_DONE,
      createdAt: Date.now(),
      lastPostponedAt: 0,
      doneAt: 0,
      dueAt: null,
      repeat: {
        days: null,
        daysOfWeek: [],
        weeks: null,
        months: null,
      },
      labels: [],

      ...details
    }

    commit(MutationTypes.ADD_TODO, todo);

    if (state.currentTodoUuid === null) {
      dispatch(ActionTypes.MARK_TODO_AS_CURRENT, todo.uuid);
    }

    return uuid;
  },

  [ActionTypes.CHANGE_TODO_DESCRIPTION]: ({commit, getters}, {uuid, description}) => {
    if (getters.todoByUuid(uuid)) {
      commit(MutationTypes.CHANGE_TODO_DESCRIPTION, {uuid, description})
    }
  },

  [ActionTypes.CHANGE_TODO_DUE_DATE]: ({commit, getters}, {uuid, dueAt}) => {
    if (getters.todoByUuid(uuid)) {
      commit(MutationTypes.CHANGE_TODO_DUE_DATE, {uuid, dueAt})
    }
  },

  [ActionTypes.CHANGE_TODO_LABELS]: ({commit, getters}, {uuid, labels}) => {
    if (getters.todoByUuid(uuid)) {
      commit(MutationTypes.CHANGE_TODO_LABELS, {uuid, labels})
    }
  },

  [ActionTypes.CHANGE_TODO_REPEAT_FREQUENCY]: ({commit, getters}, {uuid, repeat}) => {
    if (getters.todoByUuid(uuid)) {
      commit(MutationTypes.CHANGE_TODO_REPEAT_FREQUENCY, {
        uuid,
        repeat: {
          daysOfWeek: repeat.daysOfWeek?.length ? repeat.daysOfWeek : [],
          days: repeat.days || null,
          weeks: repeat.weeks || null,
          months: repeat.months || null,
        }
      });
    }

    if (getters.todoByUuid(uuid).dueAt.getTime() === 0) {
      if (repeat.daysOfWeek?.length > 0) {
        const today = (new Date()).getDay();
        const nextDay = repeat.daysOfWeek.find((day) => day >= today)
          || repeat.daysOfWeek[0];

        const dueAt = new Date();
        dueAt.setDate(dueAt.getDate() + nextDay - today + (nextDay <= today ? 7 : 0));
        commit(MutationTypes.CHANGE_TODO_DUE_DATE, {uuid, dueAt: dueAt.getTime()})
      }

      if (repeat.days || repeat.weeks || repeat.months) {
        const dueAt = new Date();
        commit(MutationTypes.CHANGE_TODO_DUE_DATE, {uuid, dueAt: dueAt.getTime()})
      }
    }
  },

  [ActionTypes.COPY_TODO]: ({dispatch, getters}, uuid) => {
    const todo = getters.todoByUuid(uuid);

    if (todo) {
      let dueAt = todo.dueAt > 0 ? new Date(todo.dueAt) : new Date(0);

      if (todo.repeat.daysOfWeek?.length > 0) {
        const today = dueAt.getDay();
        const nextDayIdx = todo.repeat.daysOfWeek.findIndex((day) => day >= today) + 1;
        const nextDay = (nextDayIdx === todo.repeat.daysOfWeek.length)
          ? todo.repeat.daysOfWeek[0] + 7
          : todo.repeat.daysOfWeek[nextDayIdx];

        dueAt.setDate(dueAt.getDate() + nextDay - today);
      }

      if (todo.repeat.days > 0) {
        dueAt.setDate(dueAt.getDate() + todo.repeat.days);
      }

      if (todo.repeat.weeks > 0) {
        dueAt.setDate(dueAt.getDate() + (todo.repeat.weeks * 7));
      }

      if (todo.repeat.months > 0) {
        dueAt = addMonthsToDate(todo.repeat.months, dueAt);
      }

      return dispatch(ActionTypes.ADD_TODO, {
        name: todo.name,
        description: todo.description,
        repeat: { ...todo.repeat },
        dueAt: dueAt.getTime(),
      })
    }
  },

  [ActionTypes.MARK_CURRENT_TODO_AS_DONE]: ({dispatch, state}) => {
    dispatch(ActionTypes.MARK_TODO_AS_DONE, state.currentTodoUuid);
  },

  [ActionTypes.MARK_TODO_AS_CURRENT]: ({commit, getters}, uuid) => {
    if (getters.todoByUuid(uuid) || uuid === null) {
      commit(MutationTypes.MARK_TODO_AS_CURRENT, uuid);
      commit(MutationTypes.UPDATE_LAST_CHANGED_AT);
    }
  },

  [ActionTypes.MARK_TODO_AS_DONE]: ({commit, dispatch, getters, state}, uuid) => {
    const todo = getters.todoByUuid(uuid);

    if (todo) {
      commit(MutationTypes.MARK_TODO_AS_DONE, uuid);

      if (uuid === state.currentTodoUuid) {
        dispatch(ActionTypes.MARK_TODO_AS_CURRENT, getters.nextAvailableTodoUuid)
      }

      if (todo.repeat.daysOfWeek.length > 0 || todo.repeat.days > 0 || todo.repeat.weeks > 0 || todo.repeat.months > 0) {
        dispatch(ActionTypes.COPY_TODO, uuid);
      }
    }
  },

  [ActionTypes.POSTPONE_CURRENT_TODO]: ({commit, dispatch, getters, state}) => {
    if (getters.currentTodo.uuid === null) {
      throw new Error('Invalid todo ID');
    }

    if (getters.nextAvailableTodoUuid === null) {
      throw new Error(NO_MORE_TODOS);
    }

    commit(MutationTypes.POSTPONE_TODO, state.currentTodoUuid);
    dispatch(ActionTypes.MARK_TODO_AS_CURRENT, getters.nextAvailableTodoUuid);
  },

  [ActionTypes.RENAME_TODO]: ({commit, getters, state}, {uuid, name}) => {
    if (getters.todoByUuid(uuid)) {
      commit(MutationTypes.RENAME_TODO, {
        uuid,
        name,
      });
    }
  },

  [ActionTypes.ADD_LABEL]: ({commit, state}, details) => {
    const uuid = uuid4();

    const label = {
      uuid,
      name: '',
      color: '#999999',

      ...details,
    };

    commit(MutationTypes.ADD_LABEL, label);

    return uuid;
  },

  [ActionTypes.CHANGE_LABEL_COLOR]: ({commit}, {uuid, color}) => {
    commit(MutationTypes.CHANGE_LABEL_COLOR, {uuid, color});
  },

  [ActionTypes.DELETE_LABEL]: ({commit}, {uuid}) => {
    commit(MutationTypes.DELETE_LABEL, {uuid});
  },

  [ActionTypes.RENAME_LABEL]: ({commit}, {uuid, name}) => {
    commit(MutationTypes.RENAME_LABEL, {uuid, name});
  },

  [ActionTypes.UPDATE_LAST_CHANGED_AT]: ({commit}) => {
    commit(MutationTypes.UPDATE_LAST_CHANGED_AT);
  },

  [ActionTypes.RESTORE_DATA_FROM_BACKUP]: ({commit}, backup) => {
    commit(MutationTypes.RESTORE_DATA_FROM_BACKUP, backup);
  },

  [ActionTypes.PUSH_MESSAGE]: ({commit}, message) => {
    commit(MutationTypes.PUSH_MESSAGE, message);
  },

  [ActionTypes.SHIFT_MESSAGE]: ({commit}) => {
    commit(MutationTypes.SHIFT_MESSAGE);
  }
};