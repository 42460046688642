<template>
    <svg viewBox="0 0 320 320"
         xmlns="http://www.w3.org/2000/svg"
         class="stroke-current"
    >
        <title>Settings</title>
        <defs>
            <path id="tooth"
                  fill="none"
                  stroke-width="20"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                  d="M 122  63
                     L 130  20
                     C 160  10, 160  10, 190  20
                     L 198  63
                     L 220  78"
                  />
        </defs>

        <circle cx="160"
                cy="160"
                r="30"
                fill="none"
                stroke-width="20"
            />

        <use href="#tooth"/>
        <use href="#tooth" transform-origin="160 160" transform="rotate(60)"/>
        <use href="#tooth" transform-origin="160 160" transform="rotate(120)"/>
        <use href="#tooth" transform-origin="160 160" transform="rotate(180)"/>
        <use href="#tooth" transform-origin="160 160" transform="rotate(240)"/>
        <use href="#tooth" transform-origin="160 160" transform="rotate(300)"/>
    </svg>
</template>
