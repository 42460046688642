import {
  DONE,
  NOT_DONE
} from "@/store/todo-statuses";

import {stopwords} from "@/store/stopwords";

import {ymd} from '@/utils/date';

export const getters = {
  currentTodo: (state, getters) => {
    if (state.currentTodoUuid) {
      return getters.todoByUuid(state.currentTodoUuid);
    }

    return {
      uuid: null,
      name: '',
      description: '',
      status: NOT_DONE,
      createdAt: new Date(0),
      lastPostponedAt: new Date(0),
      doneAt: new Date(0),
      dueAt: new Date(0),
      repeat: {
        never: true,
        days: null,
        daysOfWeek: [],
        weeks: null,
        months: null,
      },
      labels: [],
    }
  },

  todoByUuid: (state, getters) => (uuid) => {
    const idx = state.todos.findIndex((todo) => {
      return todo.uuid === uuid;
    })

    if (idx > -1) {
      const todo = state.todos[idx];

      return {
        ...todo,
        dueAt: toDate(todo.dueAt),
        labels: todo.labels ? todo.labels.filter((label) => {
          return getters.labelByUuid(label).name !== '';
        }) : [],
        repeat: {
          never: (todo.repeat.daysOfWeek.length === 0 && todo.repeat.days === null && todo.repeat.weeks === null && todo.repeat.months === null),
          ...todo.repeat,
        }
      };
    }

    return null;
  },

  nextAvailableTodoUuid: (state, getters) => {
    const ttbd = getters.thingsToBeDone;

    const idx = ttbd.findIndex((todo) => {
      return todo.uuid === state.currentTodoUuid;
    })

    if (idx > -1) {
      ttbd.splice(idx, 1);
    }

    if (ttbd.length === 0) {
      return null;
    }

    return ttbd[0].uuid;
  },

  thingsToBeDone: (state, getters) => {
    return state.todos.filter((todo) => {
        return todo.status === NOT_DONE
      },
    ).map((todo) => {
      return {
        ...todo,
        createdAt: toDate(todo.createdAt),
        dueAt: toDate(todo.dueAt),
        lastPostponedAt: toDate(todo.lastPostponedAt),
        doneAt: toDate(todo.doneAt),
        labels: todo.labels ? todo.labels.filter((label) => {
          return getters.labelByUuid(label).name !== '';
        }) : [],
        repeat: {
          never: (todo.repeat?.daysOfWeek.length === 0 && todo.repeat?.days === null && todo.repeat?.weeks === null && todo.repeat?.months === null),
          ...todo.repeat,
        }
      }
    }).sort((a, b) => {
      const today = new Date();

      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);

      const score = {
        current: 0,
        urgent: 0,
        postponed: 0,
        due: 0,
        created: 0,
      }

      // put the current todo at the top of the list
      score.current = a.uuid === state.currentTodoUuid ? -1 // push 'a' up the list
        : b.uuid === state.currentTodoUuid ? 1 // push 'b' down the list
        : 0; // neither are current, so they're equal at this point

      // prioritise urgent items
      if (a.dueAt.getTime() || b.dueAt.getTime()) {
        function urgent(d) {
          return d.dueAt.getTime() > 0
            && ymd(d.dueAt) <= ymd(today)
            && (
              d.lastPostponedAt.getTime === 0
              || ymd(d.lastPostponedAt) < ymd(today)
            );
        }
        score.urgent = urgent(a) && !urgent(b) ? -1
          : !urgent(a) && urgent(b) ? 1
          : urgent(a) && urgent(b) ? a.dueAt - b.dueAt
          : 0;
      }

      const sortingDate = (todo) => {
        let sortingDate = todo.createdAt;

        // dueAt is always after createdAt date
        if (todo.dueAt.getTime()) {
          sortingDate = todo.dueAt;
        }

        // if lastPostponed is after the dueAt, then this would be an urgent item already
        // we add fourteen days to allow upcoming items to bubble up a little faster before they become urgent
        if (todo.lastPostponedAt.getTime()) {
          sortingDate = new Date(todo.lastPostponedAt);
          sortingDate.setDate(sortingDate.getDate() + 14);
        }

        // some items that are not due for a while might have a postponed date because of the old algorithm
        // this might cause them to bubble up too fast, so we ignore the lastPostponed value if the item is not due for a bit longer
        if (todo.dueAt.getTime() && todo.lastPostponedAt.getTime()) {
          if (ymd(todo.dueAt) - ymd(todo.lastPostponedAt) > 14) {
            sortingDate = todo.dueAt;
          }
        }

        return sortingDate;
      }

      score.sort = sortingDate(a) - sortingDate(b);

      return Object.values(score).reduce((previous, current) => {
        if (previous === 0) {
          return current;
        }

        return previous;
      });
    })
  },

  thingsToBeDoneToday: (state, getters) => {
    const today = ymd(new Date());

    return getters.thingsToBeDone.filter((todo) => {
      return todo.dueAt.getTime() > 0 && ymd(todo.dueAt) <= today;
    });
  },

  thingsThatHaveBeenDone: (state, getters) => {
    return state.todos.filter((todo) => {
        return todo.status === DONE
      },
    ).sort((a, b) => {
      return b.doneAt - a.doneAt;
    }).map((todo) => {
      return {
        ...todo,
        dueAt: toDate(todo.dueAt),
        lastPostponedAt: toDate(todo.lastPostponedAt),
        doneAt: toDate(todo.doneAt),
        labels: todo.labels ? todo.labels.filter((label) => {
          return getters.labelByUuid(label).name !== '';
        }) : [],
      }
    })
  },

  thingsThatHaveBeenDoneToday: (state, getters) => {
    const today = ymd(new Date());

    return getters.thingsThatHaveBeenDone.filter((todo) => {
      return ymd(todo.doneAt) === today;
    })
  },

  thingsThatHaveBeenDoneYesterday: (state, getters) => {
    const today = new Date();
    const yesterday = ymd(new Date(today.setDate(today.getDate() - 1)));

    return getters.thingsThatHaveBeenDone.filter((todo) => {
      return ymd(todo.doneAt) === yesterday;
    })
  },

  thingsThatHaveBeenDoneLastWeek: (state, getters) => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 = Sunday
    const lastWeekStart = ymd(new Date(today.setDate(today.getDate() - dayOfWeek - 7)));
    const lastWeekEnd = ymd(new Date(today.setDate(today.getDate() + 6)));

    return getters.thingsThatHaveBeenDone.filter((todo) => {
      return ymd(todo.doneAt) >= lastWeekStart && ymd(todo.doneAt) <= lastWeekEnd;
    })
  },

  thingsThatHaveBeenDoneSevenDaysAgo: (state, getters) => {
    const today = ymd(new Date());
    const _date = new Date();
    const sevenDaysAgo = ymd(new Date(_date.setDate(_date.getDate() - 7)));

    return getters.thingsThatHaveBeenDone.filter((todo) => {
      return ymd(todo.doneAt) <= today && ymd(todo.doneAt) > sevenDaysAgo;
    })
  },

  thingsByName: (state, getters) => (name) => {
    const words = new RegExp(name.toLowerCase()
        .replace(/[^\w' ]+/g, ' ')
        .replace(/[']*/g, '')
        .replace(/\b\w{1,3}\b/g, '')
        .trim()
        .replace(/ +/g, '|')
        .split(' ')
        .filter((word) => {
          return !stopwords.includes(word);
        })
        .join(' ')
      , 'gi');

    if (words.toString() === '/(?:)/gi') {
      // nothing to search for
      return [];
    }

    return state.todos.filter((todo) => {
      return todo.name.toLowerCase().replace(/[']*/g, '').match(words);
    }).reduce((todos, currentTodo) => {
      const t = todos.findIndex((todo) => {
        return todo.name === currentTodo.name;
      });

      if (t < 0) {
        todos.push(currentTodo);
      } else {
        if (currentTodo.doneAt === 0 || (todos[t].doneAt !== 0 && (currentTodo.doneAt > todos[t].doneAt))) {
          todos[t] = currentTodo;
        }
      }

      return todos;
    }, []).sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }

      return 0;
    }).map((todo) => {
      return {
        ...todo,
        dueAt: toDate(todo.dueAt),
        lastPostponedAt: toDate(todo.lastPostponedAt),
        doneAt: toDate(todo.doneAt),
        labels: todo.labels ? todo.labels.filter((label) => {
          return getters.labelByUuid(label).name !== '';
        }) : [],
        repeat: {
          never: (todo.repeat?.daysOfWeek.length === 0 && todo.repeat?.days === null && todo.repeat?.weeks === null && todo.repeat?.months === null),
          ...todo.repeat,
        }
      }
    });
  },

  labelByUuid: (state) => (uuid) => {
    const idx = state.settings.labels.findIndex((label) => {
      return label.uuid === uuid;
    });

    if (idx > -1) {
      return state.settings.labels[idx];
    }

    return {
      uuid: '',
      name: '',
      color: 'transparent',
    }
  },

  lastChangedAt: (state) => {
    if (state.meta.lastChangedAt === undefined) {
      return new Date();
    }

    return new Date(state.meta.lastChangedAt);
  },

  message: (state) => {
    return (state.messages[0]);
  }
};

function toDate(timestamp) {
  return new Date(timestamp);
}