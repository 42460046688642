<template>
    <svg viewBox="0 0 320 320"
         xmlns="http://www.w3.org/2000/svg"
         class="stroke-current"
    >
        <title>List</title>
        <path fill="none"
              stroke-width="20"
              stroke-linejoin="round"
              stroke-linecap="round"
              d="M  30  45
                 L  60  45
                 M 100  45
                 L 305  45"
        />

        <path fill="none"
              stroke-width="20"
              stroke-linejoin="round"
              stroke-linecap="round"
              d="M  30 120
                 L  60 120
                 M 100 120
                 L 305 120"
        />

        <path fill="none"
              stroke-width="20"
              stroke-linejoin="round"
              stroke-linecap="round"
              d="M  30 195
                 L  60 195
                 M 100 195
                 L 305 195"
        />

        <path fill="none"
              stroke-width="20"
              stroke-linejoin="round"
              stroke-linecap="round"
              d="M  30 270
                 L  60 270
                 M 100 270
                 L 305 270"
        />
    </svg>
</template>
