<template>
    <h1 v-if="!missingTodo">404 Page Not Found</h1>
    <h1 v-if="missingTodo">404 To-Do Not Found</h1>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';

const route = useRoute();
const missingTodo = computed(() => route.path.substring(1, 5) === 'todo');

useHead({
  title: '404 | Cross It Off',
});
</script>