<template>
    <form @submit.prevent="save" class="mt-8 flex-col justify-center">
        <div
             class="flex narrow:flex-col
                w-full max-w-lg narrow:max-w-none
                border-2 border-gray-400 dark:border-gray-500
                rounded-lg
                outline-4 outline-transparent
                focus-within:border-blue-500
                narrow:text-xl"
        >
            <input id="name"
                   type="text"
                   inputmode="text"
                   ref="textInput"
                   v-model="name"
                   class="flex-grow
                            dark:bg-gray-300
                            outline-none
                            wide:rounded-l-lg narrow:rounded-t-lg
                            p-1
                            narrow:text-3xl dark:text-gray-900
                            narrow:w-full"
                   role="combobox"
                   aria-autocomplete="none"
                   aria-controls="existing-todo-list"
                   :aria-expanded="matches.length > 0"
                   autocomplete="off"
                   @keydown="typeahead"
                   @keyup.prevent
            />

            <submit-button text="Add"
                           class="border-inherit border-0 wide:border-l-2 narrow:border-t-2
                                wide:rounded-r narrow:rounded-b"
            ></submit-button>
        </div>

        <p v-if="error" class="text-red-700 mt-8">
            {{ error }}
        </p>

        <div aria-live="polite" class="sr-only">{{ matches.length }} items found</div>

        <ul id="existing-todo-list"
            ref="list"
            class="outline-none"
            role="listbox"
            aria-readonly
            @focusout="focus = false"
            @keydown.down.prevent="index += (index < matches.length - 1 ? 1 : 0)"
            @keydown.up.prevent="index -= (index > 0 ? 1 : 0)"
            @keydown.home.prevent="index = 0"
            @keydown.end.prevent="index = matches.length - 1"
            @keydown.esc.prevent="textInput.focus()"
            @keydown.enter.prevent="router.push({name: 'Todo', params: { uuid: matches[index].uuid }})"
        >
            <todo v-for="(todo, i) in matches"
                  :id="`todo-${i}`"
                  :key="todo.uuid"
                  :todo="todo"
                  role="option"
                  :aria-selected="index === i"
                  :tabIndex="i === 0 ? 0 : ''"
                  @focus.prevent="enterList"
                  :class="{
                    'border-gray-800': focus && index === i,
                  }"
                  class="outline-none"
            ></todo>
        </ul>
    </form>
</template>



<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import SubmitButton from '@/components/form/SubmitButton';
import Todo from '@/components/Todo';

import ActionTypes from "@/store/action-types";

const router = useRouter();
const store = useStore();

const emit = defineEmits(['save']);

const error = ref('');
const focus = ref(false);
const index = ref(-1);
const list = ref(null);
const name = ref('');
const matches = ref([]);
const textInput = ref(null);

const typeaheadTimer = ref(null);

const save = async () => {
  if (name.value !== '') {
    const uuid = await store.dispatch(ActionTypes.ADD_TODO, { name: name.value });
    emit('save', uuid);
    return;
  }

  error.value = 'Sorry, but you must give your to-do a name.';
};

const typeahead = (event) => {
  const {key, altKey, ctrlKey, metaKey} = event;

  error.value = '';

  if (typeaheadTimer.value) {
    window.clearTimeout(typeaheadTimer.value);
  }

  // if the user starts typing
  if (key === 'Unidentified'
    || key === 'Backspace'
    || key === 'Clear'
    || (key.length === 1 && !(altKey || ctrlKey || metaKey))
    || (['v', 'V'].includes(key) && ctrlKey)
  ) {
    typeaheadTimer.value = window.setTimeout(() => {
      if (textInput.value.value === '') {
        matches.value = [];
      } else {
        matches.value = store.getters.thingsByName(textInput.value.value);
      }
    }, 500);
  }

  if (matches.value.length > 0 && key === 'ArrowDown') {
    event.preventDefault();
    index.value = 0;
    list.value.focus();
  }
};

const enterList = () => {
  if (index.value < 0) {
    index.value = 0;
  }

  if (index.value >= matches.value.length) {
    index.value = matches.value.length - 1;
  }

  focus.value = true;
};

watch(index, (currentValue) =>{
  if (currentValue < 0) {
    textInput.value.removeAttribute('aria-activedescendant');
    return;
  }

  textInput.value.setAttribute('aria-activedescendant', `todo-${currentValue}`);
  list.value.children[currentValue].scrollIntoView({behavior: 'smooth', block: 'end'});
});
</script>
