<template>
    <div @click="editing = !editing && editable"
         class="grid grid-cols-todo-details outline-none
                py-2 px-1 narrow:p-0
                border-2 border-transparent rounded-lg
                focus:border-gray-400 focus:dark:border-gray-500"
         :class="{ 'cursor-pointer': editable }"
         :tabindex="editable ? 0 : -1"
         @keydown.space.prevent="editing = editable && true"
         @keydown.enter.prevent="editing = editable && true"
         ref="field"
    >
        <span class="py-1 text-blue-600 dark:text-blue-300 font-bold">Repeats</span>

        <span v-if="!editing" class="py-1">
            <span v-for="day in daysOfWeek"
                  :key="day"
                  class="pl-2"
            >
                {{ day }}
            </span>

            <span v-if="repeat.never" class="pl-2">Never</span>
            <span v-if="repeat.days" class="pl-2">{{ repeat.days === 1 ? 'Every day' : `Every ${repeat.days} days` }}</span>
            <span v-if="repeat.weeks" class="pl-2">{{ repeat.weeks === 1 ? 'Every week' : `Every ${repeat.weeks} weeks` }}</span>
            <span v-if="repeat.months" class="pl-2">{{ repeat.months === 1 ? 'Every month' : `Every ${repeat.months} months` }}</span>
        </span>
    </div>

    <form v-if="editing"
          @submit.prevent="save"
          @keydown.esc.prevent="editing = false; nextTick(() => field.focus())"
          class="border-2 border-gray-400 dark:border-gray-500
            rounded
            focus-within:border-blue-500"
    >
        <div class="p-2 py-4 border-inherit border-b-2">
            <div class="pb-4">
                <label class="mr-2
                        whitespace-nowrap"
                >
                    <input name="repeat-never"
                           ref="repeatNeverCheckBox"
                           type="checkbox"
                           value="never"
                           @click.stop="setNever"
                           :checked="settings.never"
                           class="focus:outline-blue-500"
                    />
                    Never
                </label>
            </div>

            <div class="border-t border-gray-400 pt-4">
                Every
                <div class="grid wide:grid-cols-4 narrow:grid-rows-4 narrow:grid-flow-col
                            pt-2"
                >
                    <label v-for="(day, index) in ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']"
                           :key="index"
                           class="mr-2 mb-4
                            whitespace-nowrap"
                    >
                        <input name="repeat-days-of-week"
                               type="checkbox"
                               :value="index"
                               @click.stop="setDayOfWeek(index)"
                               :checked="settings.daysOfWeek.includes(index)"
                               class="focus:outline-blue-500"
                        />
                        {{ day }}
                    </label>
                </div>
            </div>

            <div class="mt-4 pt-4 border-t border-gray-400">
                <label class="flex">
                    <span class="py-1">Every</span>
                    <input name="repeat-days"
                           type="number"
                           min="0"
                           maxlength="2"
                           v-model="settings.days"
                           class="w-12 mx-3 px-2
                                    border-2 border-gray-400 dark:border-gray-500 focus:border-blue-500
                                    outline-none
                                    dark:bg-gray-300
                                    dark:text-gray-900
                                    rounded
                                    text-right"
                    >
                    <span class="py-1">days</span>
                </label>
            </div>

            <div class="mt-4 pt-4">
                <label class="flex">
                    <span class="py-1">Every</span>
                    <input name="repeat-weeks"
                           type="number"
                           min="0"
                           maxlength="2"
                           v-model="settings.weeks"
                           class="w-12 mx-3 px-2
                                    border-2 border-gray-400 dark:border-gray-500 focus:border-blue-500
                                    outline-none
                                    dark:bg-gray-300
                                    dark:text-gray-900
                                    rounded
                                    text-right"
                    >
                    <span class="py-1">weeks</span>
                </label>
            </div>

            <div class="mt-4 pt-4">
                <label class="flex">
                    <span class="py-1">Every</span>
                    <input name="repeat-months"
                           type="number"
                           min="0"
                           maxlength="2"
                           v-model="settings.months"
                           class="w-12 mx-3 px-2
                                    border-2 border-gray-400 dark:border-gray-500 focus:border-blue-500
                                    outline-none
                                    dark:bg-gray-300
                                    dark:text-gray-900
                                    rounded
                                    text-right"
                    >
                    <span class="py-1">months</span>
                </label>
            </div>
        </div>

        <div class="flex border-inherit">
            <submit-button class="flex-grow border-0"></submit-button>

            <button @click.stop="editing = false"
                    type="button"
                    class="flex-grow
                            hover:bg-blue-200 dark:hover:bg-blue-400
                            border-inherit border-l-2
                            px-2 py-1"
            >
                Cancel
            </button>
        </div>
    </form>
</template>

<script setup>
import {computed, nextTick, reactive, ref, toRefs, watch} from 'vue';

import SubmitButton from "@/components/form/SubmitButton";

const emit = defineEmits(['changed']);

const props = defineProps({
  close: {
    type: Boolean,
    required: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  repeat: {
    type: Object,
    required: true,
  }
});

const { close, editable, repeat } = toRefs(props);

const editing = ref(false);
const field = ref(null);
const settings = reactive({
  never: repeat.value.never,
  daysOfWeek: [...repeat.value.daysOfWeek],
  days: repeat.value.days,
  weeks: repeat.value.weeks,
  months: repeat.value.months,
});
const repeatNeverCheckBox = ref(null);

const daysOfWeek = computed(() => {
  if (repeat.value.daysOfWeek.length === 0) {
    return false;
  }

  const daysOfWeek = repeat.value.daysOfWeek;

  return daysOfWeek.sort().map((day) => {
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return days[day];
  });
});

watch(() => settings.days, (currentValue, oldValue) => {
  if (currentValue !== null && currentValue !== oldValue) {
    settings.never = false;
    settings.daysOfWeek = [];
    settings.weeks = null;
    settings.months = null;
  }
});

watch(() => settings.weeks, (currentValue, oldValue) => {
  if (currentValue !== null && currentValue !== oldValue) {
    settings.never = false;
    settings.daysOfWeek = [];
    settings.days = null;
    settings.months = null;
  }
});

watch(() => settings.months, (currentValue, oldValue) => {
  if (currentValue !== null && currentValue !== oldValue) {
    settings.never = false;
    settings.daysOfWeek = [];
    settings.days = null;
    settings.weeks = null;
  }
});

watch(editing, (currentValue, oldValue) => {
  if (currentValue) {
    nextTick(() => {
      settings.never = repeat.value.never;
      settings.daysOfWeek = [...repeat.value.daysOfWeek];
      settings.days = repeat.value.days;
      settings.weeks = repeat.value.weeks;
      settings.months = repeat.value.months;

      repeatNeverCheckBox.value.focus();
    });
  }
});

watch(close, (currentValue) => {
  if (currentValue) {
    editing.value = false;
  }
});

const setNever = () => {
  settings.never = true;
  settings.daysOfWeek = [];
  settings.days = null;
  settings.weeks = null;
  settings.months = null;
};

const setDayOfWeek = (day) => {
  settings.never = false;
  settings.days = null;
  settings.weeks = null;
  settings.months = null;

  const idx = settings.daysOfWeek.findIndex((d) => d === day);

  if (idx > -1) {
    settings.daysOfWeek.splice(idx, 1);
  } else {
    settings.daysOfWeek.push(day);
  }
};

const save = () => {
  if (settings.never) {
    emit('changed', {});
  }

  if (settings.daysOfWeek.length) {
    settings.daysOfWeek.sort();
    emit('changed', {daysOfWeek: settings.daysOfWeek});
  }

  if (settings.days !== null) {
    emit('changed', {days: settings.days});
  }

  if (settings.weeks !== null) {
    emit('changed', {weeks: settings.weeks});
  }

  if (settings.months !== null) {
    emit('changed', {months: settings.months});
  }

  editing.value = false;
};
</script>

<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}
</style>
