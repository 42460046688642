import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import About from '@/views/About';
import AddTodo from '@/views/AddTodo';
import AllDone from '@/views/AllDone';
import NotFound from "@/views/NotFound";
import Privacy from '@/views/Privacy';
import Settings from '@/views/Settings';
import Share from '@/views/Share';
import Todo from '@/views/Todo';
import Todos from '@/views/Todos';
import Welcome from '@/views/Welcome';

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
  },

  {
    path: '/todo/:uuid?',
    name: 'Todo',
    component: Todo,
    beforeEnter: (to, from) => {
      if (to.params.uuid && !store.getters.todoByUuid(to.params.uuid)) {
        // 404
        return {
          name: 'NotFound',
          replace: true,
        };
      }
    }
  },

  {
    path: '/todos/:filter?',
    name: 'Todos',
    component: Todos,
  },

  {
    path: '/share/:filter',
    name: 'Share',
    component: Share,
  },

  {
    path: '/add-todo',
    name: 'AddTodo',
    component: AddTodo,
  },

  {
    path: '/all-done',
    name: 'AllDone',
    component: AllDone,
  },

  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },

  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy,
  },

  {
    path: '/about',
    name: 'About',
    component: About,
  },

  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const waitForStorageToBeReady = async (to, from) => {
  await store.restored;
};
router.beforeEach(waitForStorageToBeReady);

const ensureCanonicalTodo = (to, from) => {
  if (to.name === 'Todo'
    && (to.params.uuid === undefined || to.params.uuid === '')
  ) {
    if (store.state.currentTodoUuid) {
      return `/todo/${store.state.currentTodoUuid}`;
    }

    return { name: 'AllDone' };
  }
}
router.beforeEach(ensureCanonicalTodo);

export default router;
