<template>
    <div class="flex flex-col justify-between min-h-full">
        <div>
            <h1>Cross It Off</h1>

            <p class="mt-8 transition-opacity duration-1000"
               :class="opacity(1)"
            >
                Hi there! Welcome to <em class="italic whitespace-nowrap">Cross It Off</em>.
            </p>

            <p class="mt-4 transition-opacity duration-1000"
               :class="opacity(2)"
            >
                <span>
                    Let's get you started by adding your first <span class="whitespace-nowrap">to-do</span>:
                </span>
            </p>

            <new-todo class="transition-opacity duration-1000"
                      :class="opacity(3)"
                      @save="router.push({name: 'Todo'})"
            ></new-todo>
        </div>

        <div class="flex gap-10 mt-10 transition-opacity duration-1000"
             :class="opacity(2)"
        >
            <router-link :to="{ name: 'About' }">About</router-link>
            <router-link :to="{ name: 'Privacy' }">Privacy</router-link>
        </div>
    </div>
</template>

<script setup>
import { nextTick, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head';

import NewTodo from '@/components/NewTodo';

import isEmpty from '@/utils/isEmpty';

const router = useRouter();
const store = useStore();

const revealStep = ref(0);

const opacity = (step) => {
  if (revealStep.value > step) {
    return { 'opacity-100': true };
  }

  return { 'opacity-0': true };
};

const reveal = () => {
  revealStep.value += 1;

  switch (revealStep.value) {
    case 1:
      setTimeout(reveal, 500);
      break;
    case 2:
      setTimeout(reveal, 1500);
      break;
    case 3:
      setTimeout(reveal, 1000);
      break;
    case 4:
      setTimeout(reveal, 2000);
      break;

    default:
    // no-op
  }
};

onMounted(() => {
  if (store.state.currentTodoUuid !== null) {
    router.push({name: 'Todo'});
    return;
  }
  if (!isEmpty(store.state.todos)) {
    router.push({name: 'AllDone'});
    return;
  }

  nextTick(() => {
    reveal();
  });
});

useHead({
  title: 'Cross It Off',
});
</script>
