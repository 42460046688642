<template>
    <div class="mt-2"
    >
        <div class="grid grid-cols-2
                border-2 border-gray-400 dark:border-gray-500
                rounded-lg">
            <router-link :to="{ name: 'Todos' }"
                         class="px-2 py-3 hover:bg-blue-400 hover:text-inherit whitespace-nowrap no-underline text-center cursor-pointer"
                         :class="buttonStyles.thingsToBeDone"
                         @click="filter = ''"
            >
                To-Do
            </router-link>

            <router-link :to="{ name: 'Todos', params: { filter: 'done' } }"
                         class="px-2 py-3 hover:bg-blue-400 hover:text-inherit whitespace-nowrap no-underline text-center cursor-pointer"
                         :class="buttonStyles.done"
                         @click="filter = 'done'"
            >
                Done
            </router-link>
        </div>

        <div v-if="filter !== ''"
             class="grid grid-cols-4 narrow:grid-cols-2 narrow:grid-rows-2
                    mt-4
                    border-2 border-gray-400 dark:border-gray-500
                    rounded-lg"
        >
            <router-link :to="{ name: 'Todos', params: { filter: 'last-week' } }"
                         class="p-2 hover:bg-blue-400 hover:text-inherit no-underline text-center cursor-pointer"
                         :class="buttonStyles.lastWeek"
                         @click="filter = 'last-week'"
            >
                Last Week
            </router-link>

            <router-link :to="{ name: 'Todos', params: { filter: 'last-seven-days' } }"
                         class="p-2 border-inherit border-l-2 hover:bg-blue-400 hover:text-inherit no-underline text-center cursor-pointer"
                         :class="buttonStyles.lastSevenDays"
                         @click="filter = 'last-seven-days'"
            >
                Last 7 Days
            </router-link>

            <router-link :to="{ name: 'Todos', params: { filter: 'yesterday' } }"
                         class="p-2 border-inherit wide:border-l-2 narrow:border-t-2 hover:bg-blue-400 hover:text-inherit no-underline text-center cursor-pointer"
                         :class="buttonStyles.yesterday"
                         @click="filter = 'yesterday'"
            >
                Yesterday
            </router-link>

            <router-link :to="{ name: 'Todos', params: { filter: 'done' } }"
                         class="p-2 border-inherit border-l-2 narrow:border-t-2 hover:bg-blue-400 hover:text-inherit no-underline text-center cursor-pointer"
                         :class="buttonStyles.today"
                         @click="filter = 'done'"
            >
                Today
            </router-link>
        </div>

        <router-link v-if="filter !== ''" :to="{ name: 'Share', params: { filter } }"
                     class="no-underline hover:text-inherit">
            <share-button class="mt-4 w-full rounded-lg"></share-button>
        </router-link>

        <todos-filter v-if="filter === ''"
                      @filterByName="(value) => { filterByName = value}"
                      @filterByLabels="(value) => { filterByLabels = value}"
        ></todos-filter>
    </div>

    <div v-if="filter === '' && filterByName === ''&& filterByLabels.length === 0" class="mt-12">
        <div v-if="isEmpty(list)">
            <h1>There's nothing left to do</h1>

            <p>
                Or have you thought of something else?
            </p>

            <new-todo @save="router.push({name: 'Todo'})"></new-todo>
        </div>
    </div>

    <ul class="mt-20 outline-none"
        role="listbox"
        aria-readonly
        ref="todoList"
        @focusout="focus = false; index = -1"
        @keydown.down.prevent="index += (index < list.length - 1 ? 1 : 0)"
        @keydown.up.prevent="index -= (index > 0 ? 1 : 0)"
        @keydown.home.prevent="index = 0"
        @keydown.end.prevent="index = list.length - 1"
        @keydown.enter.prevent="router.push({name: 'Todo', params: { uuid: list[index].uuid }})"
    >
        <todo v-for="(todo, i) in list"
              role="option link"
              :aria-selected="index === i"
              :id="`todo-${i}`"
              :key="todo.uuid"
              :todo="todo"
              :tabIndex="i === 0 ? 0 : ''"
              @focus.prevent="focus = true; index = 0"
              :class="{
                'border-gray-800': focus && index === i,
              }"
              class="outline-none"
        ></todo>
    </ul>
</template>

<script setup>
import {computed, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {useHead} from '@vueuse/head';

import NewTodo from '@/components/NewTodo';
import ShareButton from '@/components/ShareButton';
import Todo from '@/components/Todo';
import TodosFilter from '@/components/TodosFilter';

import isEmpty from '@/utils/isEmpty';

const route = useRoute();
const router = useRouter();
const store = useStore();

const filter = ref(route.params.filter ?? '');
const focus = ref(false);
const index = ref(0);
const filterByName = ref('');
const filterByLabels = ref([]);
const todoList = ref(null);

const buttonStyles = computed(() => {
  return {
    thingsToBeDone: {
      'bg-blue-600': filter.value === '',
      'text-white': filter.value === '',
      'font-bold': filter.value === '',
    },
    done: {
      'bg-blue-600': filter.value !== '',
      'text-white': filter.value !== '',
      'font-bold': filter.value !== '',
    },
    today: {
      'bg-blue-600': filter.value === 'done',
      'text-white': filter.value === 'done',
    },
    yesterday: {
      'bg-blue-600': filter.value === 'yesterday',
      'text-white': filter.value === 'yesterday',
    },
    lastSevenDays: {
      'bg-blue-600': filter.value === 'last-seven-days',
      'text-white': filter.value === 'last-seven-days',
    },
    lastWeek: {
      'bg-blue-600': filter.value === 'last-week',
      'text-white': filter.value === 'last-week',
    },
  }
})

const list = computed(() => {
  switch (filter.value) {
    case 'done':
      return store.getters.thingsThatHaveBeenDoneToday;
    case 'yesterday':
      return store.getters.thingsThatHaveBeenDoneYesterday;
    case 'last-week':
      return store.getters.thingsThatHaveBeenDoneLastWeek;
    case 'last-seven-days':
      return store.getters.thingsThatHaveBeenDoneSevenDaysAgo;

    default:
      return store.getters.thingsToBeDone
        .filter((todo) => {
          if (filterByName.value === '') {
            return true;
          }

          return todo.name.toLowerCase().includes(filterByName.value.toLowerCase());
        })
        .filter((todo) => {
          if (filterByLabels.value.length === 0) {
            return true;
          }

          if (filterByLabels.value.length === 1 && filterByLabels.value[0] === 'unlabelled' && todo.labels.length === 0) {
            return true;
          }

          return filterByLabels.value.every((label) => todo.labels.includes(label));
        });
  }
});

watch(index, (currentValue) => {
  if (currentValue < 0) {
    todoList.value.removeAttribute('aria-activedescendant');
    return;
  }

  todoList.value.setAttribute('aria-activedescendant', `todo-${currentValue}`);
  todoList.value.children[currentValue].scrollIntoView({behavior: 'smooth', block: 'end'});
});

useHead({
  title: computed(() => {
    switch (filter.value) {
      case 'done':
        return 'Today | Cross It Off';

      case 'yesterday':
        return 'Yesterday | Cross It Off';

      case 'last-seven-days':
        return 'Last Seven Days | Cross It Off'

      case 'last-week':
        return 'Last Week | Cross It Off';

      default:
        if (isEmpty(list.value)) {
          return 'Crossed It Off';
        }

        return 'Todos | Cross It Off';
    }
  }),
});
</script>
