import { createApp } from 'vue';
import App from './App';
import router from './router';
import store from './store';
import { createHead } from '@vueuse/head'
import './assets/tailwind.css';
import './registerServiceWorker'

createApp(App)
  .use(store)
  .use(router)
  .use(createHead())
  .mount('#app');
