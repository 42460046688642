<template>
    <svg viewBox="0 0 320 320"
         xmlns="http://www.w3.org/2000/svg"
    >
        <title>{{ title }}</title>
        <path :fill="fill"
              :stroke="stroke"
              stroke-width="20"
              stroke-linejoin="round"
              stroke-linecap="round"
              d="M  15 195
                   105 230
                   305  15
                   125 305
                    15 195"
        />
    </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'selected',
    },
    stroke: {
      type: String,
      default: '#ffffff',
    },
    fill: {
      type: String,
      default: '#ffffff',
    }
  }
}
</script>