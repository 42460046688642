import {Buffer} from "buffer";

export async function encrypt(source, key) {
  const aesKey = await importKey(key);
  const iv = crypto.getRandomValues(new Uint8Array(16));

  const encoder = new TextEncoder();
  const encodedSource = encoder.encode(source);

  return {
    data: Buffer.from(await crypto.subtle.encrypt(
      {
        name: 'AES-CBC',
        iv
      },
      aesKey,
      encodedSource
    )).toString('base64'),
    check: Buffer.from(iv).toString('base64'),
  }
}

export async function decrypt(source, key) {
  const aesKey = await importKey(key);

  return Buffer.from(await crypto.subtle.decrypt(
    {
      name: 'AES-CBC',
      iv: Uint8Array.from(Buffer.from(source.check, 'base64'))
    },
    aesKey,
    Buffer.from(source.data, 'base64').buffer
  )).toString();
}

async function importKey(key) {
  return await crypto.subtle.importKey(
    'raw',
    Buffer.from(key, 'base64'),
    'AES-CBC',
    true,
    ['encrypt', 'decrypt'],
  );
}