<template>
    <nav class="flex gap-10 p-4">
        <router-link :to="{ name: 'About' }">About</router-link>
        <router-link :to="{ name: 'Privacy' }">Privacy</router-link>
        <router-link :to="{ name: 'Settings' }">Settings</router-link>
    </nav>

    <h1 class="mt-8">About</h1>

    <p class="mt-8"><em class="italic">Cross It Off</em> is a to-do app which helps you to get things done instead of wasting time managing your list.</p>

    <p class="mt-8">While you may have many things to do, you can only do one at a time (multi-tasking is stopping and starting multiple things rapidly to give the illusion that you're doing more than one thing at once).</p>

    <p class="mt-8">By default, <em class="italic">Cross It Off</em> will only show you one thing that needs doing until you either get it done or put it off until later.</p>

    <p class="mt-8">You can still review and manage all of your to-dos, and pick any one of them to be your current thing to done, but <em class="italic">Cross It Off</em> aims to keep you focused on the current thing at hand.</p>

    <p class="mt-8"><a href="https://about.crossitoff.app">Find out more</a></p>

    <hr class="mt-8 border-t-2 border-gray-300"/>

    <p class="mt-8">
        <em class="italic">Cross It Off</em> is made by <a href="https://savvywombat.com.au/">Savvy Wombat</a>.
    </p>
</template>

<script setup>
import { useHead } from '@vueuse/head';

useHead({
  title: 'About | Cross It Off',
});
</script>