<template>
    <ul role="listbox"
        ref="labelListBox"
        tabindex="0"
        class="px-2
                    border-inherit border-b-2
                    outline-none"
        :aria-activedescendant="availableLabels[active]?.uuid"
        @focus="active = 0"
        @blur="active = -1"
        @keydown.up="up"
        @keydown.down="down"
        @keydown.space="toggle(availableLabels[active]?.uuid)"
        @keydown.enter.prevent="toggle(availableLabels[active]?.uuid)"
    >
        <li v-for="label in availableLabels"
            :id="label.uuid"
            :key="label.uuid"
            :ref="label.uuid"
            role="option"
            class="flex
                            bg-blue-600
                            text-white
                            hover:text-black
                            px-1
                            my-2
                            w-full
                            rounded
                            cursor-pointer
                            focus:text-white"
            :class="activeOptionStyles(label.uuid)"
            :style="{ backgroundColor: label.color }"
            @click="optionClicked(label.uuid)"
        >
            <tick v-if="selectedLabels.indexOf(label.uuid) >= 0"
                  alt="selected"
                  class="w-4 h-4 ml-1 my-auto"
            />
            <span v-if="selectedLabels.indexOf(label.uuid) === -1" class="w-4 ml-1"></span>

            <span class="ml-1">{{ label.name }}</span>
        </li>
    </ul>
</template>

<script setup>
import {computed, nextTick, onMounted, ref, toRefs} from "vue";
import { useStore } from 'vuex';

import Tick from "@/components/ui/Tick";

const store = useStore();

const emit = defineEmits(['changed']);

const props = defineProps({
  availableLabels: {
    type: Array,
    require: true,
  },
  focusOnOpen: {
    type: Boolean,
    default: true,
  },
  selectedLabels: {
    type: Array,
    require: true,
  }
});

const { availableLabels, focusOnOpen, selectedLabels } = toRefs(props);

const labelListBox = ref(null);

const active = ref(-1);

const activeOptionStyles = (uuid) => {
  return {
    'outline': uuid === availableLabels.value[active.value]?.uuid,
    'outline-blue-800': uuid === availableLabels.value[active.value]?.uuid,
  }
};

const up = () => {
  if (active.value > 0) {
    active.value--;
  }
};

const down = () => {
  if (active.value < (availableLabels.value.length - 1)) {
    active.value++;
  }
};

const toggle = (uuid) => {
  const idx = selectedLabels.value.indexOf(uuid);

  if (idx === -1) {
    selectedLabels.value.push(uuid);
  } else {
    selectedLabels.value.splice(idx, 1);
  }


  emit('changed', selectedLabels.value);
};

const optionClicked = (uuid) => {
  toggle(uuid);

  active.value = -1;
};

onMounted(() => {
  if (focusOnOpen.value) {
    nextTick(() => {
      active.value = 0;
      labelListBox.value.focus();
    });
  }
})
</script>