import MutationTypes from "@/store/mutation-types";

import {
  DONE
} from "@/store/todo-statuses";

export const mutations = {
  [MutationTypes.ADD_TODO]: (state, todo) => {
    state.todos.push(todo);
  },

  [MutationTypes.CHANGE_TODO_DESCRIPTION]: (state, {uuid, description}) => {
    const idx = state.todos.findIndex((todo) => {
      return todo.uuid === uuid;
    })

    if (idx > -1) {
      state.todos[idx].description = description;
    }
  },

  [MutationTypes.CHANGE_TODO_DUE_DATE]: (state, {uuid, dueAt}) => {
    const idx = state.todos.findIndex((todo) => {
      return todo.uuid === uuid;
    })

    if (idx > -1) {
      state.todos[idx].dueAt = dueAt;
    }
  },

  [MutationTypes.CHANGE_TODO_LABELS]: (state, {uuid, labels}) => {
    const idx = state.todos.findIndex((todo) => {
      return todo.uuid === uuid;
    })

    if (idx > -1) {
      state.todos[idx].labels = labels;
    }
  },

  [MutationTypes.CHANGE_TODO_REPEAT_FREQUENCY]: (state, {uuid, repeat}) => {
    const idx = state.todos.findIndex((todo) => {
      return todo.uuid === uuid;
    })


    if (idx > -1) {
      state.todos[idx].repeat = {
        days: null,
        daysOfWeek: [],
        weeks: null,
        months: null,

        ...repeat,
      };
    }
  },

  [MutationTypes.MARK_TODO_AS_CURRENT]: (state, uuid) => {
    state.currentTodoUuid = uuid;
  },

  [MutationTypes.MARK_TODO_AS_DONE]: (state, uuid) => {
    const idx = state.todos.findIndex((todo) => {
      return todo.uuid === uuid;
    })

    if (idx > -1) {
      state.todos[idx].status = DONE;
      state.todos[idx].doneAt = Date.now();
    }
  },

  [MutationTypes.POSTPONE_TODO]: (state, uuid) => {
    const idx = state.todos.findIndex((todo) => {
      return todo.uuid === uuid;
    })

    if (idx > -1) {
      state.todos[idx].lastPostponedAt = Date.now();
    }
  },

  [MutationTypes.RENAME_TODO]: (state, {uuid, name}) => {
    const idx = state.todos.findIndex((todo) => {
      return todo.uuid === uuid;
    })

    if (idx > -1) {
      state.todos[idx].name = name;
    }
  },

  [MutationTypes.ADD_LABEL]: (state, label) => {
    state.settings.labels.push(label);
  },

  [MutationTypes.CHANGE_LABEL_COLOR]: (state, {uuid, color}) => {
    const idx = state.settings.labels.findIndex((label) => {
      return label.uuid === uuid;
    });

    if (idx > -1) {
      state.settings.labels[idx].color = color;
    }
  },

  [MutationTypes.DELETE_LABEL]: (state, {uuid}) => {
    const idx = state.settings.labels.findIndex((label) => {
      return label.uuid === uuid;
    });

    if (idx > -1) {
      state.settings.labels.splice(idx, 1);
    }
  },

  [MutationTypes.RENAME_LABEL]: (state, {uuid, name}) => {
    const idx = state.settings.labels.findIndex((label) => {
      return label.uuid === uuid;
    });

    if (idx > -1) {
      state.settings.labels[idx].name = name;
    }
  },

  [MutationTypes.UPDATE_LAST_CHANGED_AT]: (state) => {
    const now = new Date();
    state.meta.lastChangedAt = now.getTime();
  },

  [MutationTypes.DELETE_ALL_LOCAL_DATA]: (state) => {
    state.currentTodoUuid = null;
    state.todos = [];
    state.settings = {};
    state.messages = [];
  },

  [MutationTypes.RESTORE_DATA_FROM_BACKUP]: (state, backup) => {
    state.currentTodoUuid = backup.currentTodoUuid;
    state.todos = backup.todos;
    state.settings = backup.settings;
  },

  [MutationTypes.PUSH_MESSAGE]: (state, message) => {
    state.messages.push(message);
  },

  [MutationTypes.SHIFT_MESSAGE]: (state) => {
    state.messages.shift();
  },
};