<template>
    <nav class="flex gap-10 p-4">
        <router-link :to="{ name: 'About' }">About</router-link>
        <router-link :to="{ name: 'Privacy' }">Privacy</router-link>
        <router-link :to="{ name: 'Settings' }">Settings</router-link>
    </nav>

    <h1 class="mt-8">Settings</h1>

    <label-settings></label-settings>

    <hr class="my-8 border-t-2 border-gray-300"/>

    <backup-settings></backup-settings>

    <hr class="my-8 border-t-2 border-gray-300"/>

    <danger-settings></danger-settings>
</template>

<script setup>
import { useHead } from '@vueuse/head';

import DangerSettings from "@/components/DangerSettings";
import LabelSettings from "@/components/LabelSettings";
import BackupSettings from "@/components/BackupSettings";

useHead({
  title: 'Settings | Cross It Off',
});
</script>