<template>
    <div>
        <h2 @click="expanded = !expanded"
            class="mt-4 p-2 cursor-pointer hover:bg-blue-300"
        >
            <input type="image"
                   :src="expanded ? '/img/ui/chevron-up.svg' : '/img/ui/chevron-down.svg'"
                   class="text-gray-900 dark:text-gray-100
                            stroke-current
                            w-6
                            mr-2"
                   tabindex="0"
                   aria-controls="backupSettings"
                   :alt="expanded ? 'collapse' : 'expand'"
            />

            Backup / Restore
        </h2>

        <div v-if="expanded"
             id="backupSettings"
             :aria-expanded="expanded"
             class="p-2"
        >
            <h3 class="my-4">Backup</h3>

            <p>
                Create a backup of your to-do and label data
            </p>

            <submit-button text="Create backup"
                           class="rounded"
                           @click="createBackup"
            >
            </submit-button>

            <h3 class="my-4 mt-12">Restore</h3>

            <p class="text-red-700 dark:text-red-500">
                Restoring will replace all of your to-do and label data
            </p>

            <label class="relative
                        bg-blue-600 hover:bg-blue-200 dark:hover:bg-blue-400 px-2 py-1
                        border-2 border-gray-400 dark:border-gray-500
                        px-2 py-1
                        text-white hover:text-gray-900 dark:hover:text-white
                        rounded
                        focus-within:outline focus-within:outline-2 focus-within:outline-black
                        cursor-pointer"
            >
                Choose file to restore from

                <input class="sr-only" type="file" @change="restoreBackup"/>
            </label>


        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue';
import {useStore} from 'vuex';
import {Buffer} from 'buffer';

import {encrypt, decrypt} from '@/utils/crypt';

import SubmitButton from '@/components/form/SubmitButton'

import ActionTypes from "@/store/action-types";

const store = useStore();

const expanded = ref(false);

const createBackup = async () => {
  try {
    const now = new Date();
    const stamp = `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}-${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;
    const data = Buffer.from(JSON.stringify({
      currentTodoUuid: store.state.currentTodoUuid,
      todos: store.state.todos,
      settings: store.state.settings,
    })).toString('base64');

    const payload = Buffer.from(JSON.stringify(await encrypt(data, process.env.VUE_APP_PWA_KEY))).toString('base64');

    const downloadLink = document.createElement('a');
    downloadLink.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(payload));
    downloadLink.download = 'cross-it-off-' + stamp + '.txt';
    downloadLink.click();

    downloadLink.remove();

    await store.dispatch(ActionTypes.PUSH_MESSAGE, {
      type: 'message',
      message: 'Your backup has been downloaded to \'cross-it-off-' + stamp + '.txt\'',
    });
  } catch (error) {
    console.error('there was an error creating the backup');

    await store.dispatch(ActionTypes.PUSH_MESSAGE, {
      type: 'error',
      message: 'There was an error creating the backup',
    });
  }
};

const restoreBackup = async (event) => {
  try {
    if (event.target.files[0] && event.target.files[0].type === 'text/plain') {
      const file = event.target.files[0];
      const payload = await file.text();
      const decrypted = await decrypt(JSON.parse(Buffer.from(payload, 'base64').toString()), process.env.VUE_APP_PWA_KEY);
      const restoredData = JSON.parse(Buffer.from(decrypted, 'base64').toString());

      await store.dispatch(ActionTypes.RESTORE_DATA_FROM_BACKUP, restoredData);

      await store.dispatch(ActionTypes.PUSH_MESSAGE, {
        type: 'message',
        message: 'Your backup has been restored',
      });
    }
  } catch (error) {
    console.error('there was an error restoring the backup');

    await store.dispatch(ActionTypes.PUSH_MESSAGE, {
      type: 'error',
      message: 'There was an error restoring the backup',
    });
  }
}
</script>