<template>
    <div>
        <h2 @click="expanded = !expanded"
            class="mt-4 p-2 text-red-700 dark:text-red-500 cursor-pointer hover:bg-blue-300"
        >
            <input type="image"
                   :src="expanded ? '/img/ui/chevron-up.svg' : '/img/ui/chevron-down.svg'"
                   class="text-red-700 dark:text-red-500
                            stroke-current
                            w-6
                            mr-2"
                   tabindex="0"
                   aria-controls="danger-area"
                   :alt="expanded ? 'collapse' : 'expand'"
            />

            Danger Area
        </h2>

        <div v-show="expanded"
             id="danger-area"
             :aria-expanded="expanded"
             class="p-2"
        >
            <h3 class="text-red-700 dark:text-red-500">Delete local storage</h3>

            <p>
                This will delete all of your to-dos and settings.<br />
                This action is irreversible.
            </p>

            <button @click="showConfirmDeletion = true"
                    class="border-2 border-red-700 px-2 py-1 text-red-700 hover:bg-red-400 hover:text-red-900 rounded dark:text-red-500 dark:border-red-500"
            >
                Delete
            </button>

            <div v-if="showConfirmDeletion"
                 class="fixed flex bg-black bg-opacity-70 top-0 left-0 h-screen w-screen items-center justify-center"
            >
                <div
                        class="border-2 border-red-700 bg-white p-4 rounded text-red-700 shadow-2xl dark:bg-gray-800 dark:text-red-500"
                >
                    <h2 class="font-bold mb-8">Confirm deletion</h2>
                    <p>
                        To confirm deletion, please copy
                        <span class="bg-gray-700 text-white px-2 dark:bg-gray-100 dark:bg-gray-500">
                                {{ confirmDeletionCode }}
                            </span>
                        into the input below:
                    </p>

                    <input type="text"
                           class="flex-grow border border-gray-400 p-1 narrow:h-16 text-gray-900"
                           ref="confirmDeletionCode"
                           autocapitalize="off"
                    />

                    <div class="flex gap-4 mt-8 justify-between">
                        <button @click="showConfirmDeletion = false"
                                type="button"
                                class="border-2 border-gray-300 px-2 py-1 rounded text-gray-900 hover:bg-blue-400 dark:text-gray-100"
                        >
                            Cancel
                        </button>

                        <button @click="confirmDeletion"
                                type="button"
                                class="border-2 border-red-700 px-2 py-1 rounded text-red-700 hover:bg-red-400 hover:text-red-900 dark:border-red-500 dark:text-red-500"
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MutationTypes from "@/store/mutation-types";

export default {
  data() {
    return {
      expanded: false,
      confirmDeletionCode: 'a1b2c3',
      showConfirmDeletion: false,
    }
  },
  methods: {
    confirmDeletion() {
      if (this.$refs.confirmDeletionCode.value === this.confirmDeletionCode) {
        this.$store.commit(MutationTypes.DELETE_ALL_LOCAL_DATA);
        this.$nextTick(() => {
          this.$router.push({ name: 'Welcome' });
        })
      }
    },
  }
}
</script>