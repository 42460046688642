<template>
    <li role="link"
        class="grid grid-areas-todo-in-list grid-cols-todo-in-list narrow:grid-areas-todo-in-list-narrow
            gap-x-4 w-full p-2 first:mt-4 mb-4
            border-2 border-gray-400
            rounded-lg
            cursor-pointer
            hover:bg-blue-200 dark:hover:bg-blue-400"
        :class="{
              'bg-blue-100': todo.uuid === currentTodoUuid,
              'dark:bg-gray-600': todo.uuid === currentTodoUuid,
            }"
        @click="router.push({name: 'Todo', params: { uuid: todo.uuid }})"
    >
        <span class="grid-in-name text-blue-600 dark:text-blue-300 font-bold pl-2">
            {{ todo.status === DONE ? '☑' : '' }}
            {{ todo.uuid === currentTodoUuid ? '↪' : ''}}
            {{ todo.name }}
        </span>

        <div class="grid-in-labels narrow:mt-2 text-right narrow:text-left"
             aria-label="Labels"
        >
            <span v-for="label in todo.labels"
                  :key="label"
                  class="inline-block
                          text-white
                          text-left
                          rounded-xl
                          px-2 py-1
                          mr-2 mb-2"
                  :style="{ backgroundColor: labelByUuid(label).color }"
            >
                {{ labelByUuid(label).name }}
            </span>
        </div>

        <div class="grid-in-date">
            <div v-if="todo.status === NOT_DONE && todo.dueAt.getTime() !== 0" :class="dueAtStyles" class="mt-2 px-2 py-1 rounded-xl wide:inline-block">
                Due:
                <time :datetime="`${todo.dueAt.getFullYear()}-${todo.dueAt.getMonth().toString().padStart(2, '0')}-${todo.dueAt.getDate().toString().padStart(2, '0')}`"
                      :aria-label="`${dayNames[todo.dueAt.getDay()].long}Cross, ${ordinal(todo.dueAt.getDate())} of ${monthNames[todo.dueAt.getMonth()].long} ${todo.dueAt.getFullYear()}`"
                >
                    {{ new Intl.DateTimeFormat('default', {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'}).format(todo.dueAt) }}
                </time>
            </div>

            <div v-if="todo.status === NOT_DONE && !todo.repeat.never" class="mt-2 px-2 py-1">
                Repeats every:
                <abbr v-for="day in daysOfWeek"
                      :key="day"
                      :title="dayNames[day].long"
                      :aria-label="dayNames[day].long"
                      class="pl-2"
                >
                    {{ dayNames[day].short }}
                </abbr>
                <span v-if="todo.repeat.days" class="pl-2">{{ todo.repeat.days }} days</span>
                <span v-if="todo.repeat.weeks" class="pl-2">{{ todo.repeat.weeks }} weeks</span>
                <span v-if="todo.repeat.months" class="pl-2">{{ todo.repeat.months }} months</span>
            </div>

            <div v-if="todo.status === DONE && todo.doneAt.getTime() !== 0" class="mt-2 px-2 py-1 rounded-xl wide:inline-block">
                Done: {{ new Intl.DateTimeFormat('default', {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'}).format(todo.doneAt) }}
            </div>
        </div>
    </li>
</template>

<script setup>
import { computed, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { dayNames, monthNames, ymd } from "@/utils/date";
import ordinal from '@/utils/ordinal';

import { DONE, NOT_DONE } from '@/store/todo-statuses';



const router = useRouter();
const store = useStore();

const props = defineProps({
  todo: {
    type: Object,
    required: true,
  }
});

const { todo } = toRefs(props);

const currentTodoUuid = computed(() => store.state.currentTodoUuid);
const labelByUuid = computed(() => store.getters.labelByUuid);

const dueAtStyles = computed(() => {
  if (todo.value.dueAt.getTime() === 0) {
    return {};
  }

  return {
    'bg-red-500': ymd(new Date()) > ymd(todo.value.dueAt),
    'bg-amber-500': ymd(new Date()) === ymd(todo.value.dueAt),
    'text-gray-100': ymd(new Date()) >= ymd(todo.value.dueAt),
  };
});

const daysOfWeek = computed(() => {
  if (todo.value.repeat.daysOfWeek.length === 0) {
    return false;
  }

  const daysOfWeek = todo.value.repeat.daysOfWeek;

  return daysOfWeek.sort();
});
</script>