export default {
  ADD_TODO: 'Add a new todo to the list',
  CHANGE_TODO_DESCRIPTION: 'Change description of todo',
  CHANGE_TODO_DUE_DATE: 'Change due date of todo',
  CHANGE_TODO_LABELS: 'Change labels of todo',
  CHANGE_TODO_REPEAT_FREQUENCY: 'Change repeat frequency of todo',
  MARK_TODO_AS_CURRENT: 'Mark todo as current',
  MARK_TODO_AS_DONE: 'Mark todo as done',
  POSTPONE_TODO: 'Postpone todo',
  RENAME_TODO: 'Rename todo',

  ADD_LABEL: 'Add a new label',
  CHANGE_LABEL_COLOR: 'Change the color of a label',
  DELETE_LABEL: 'Delete a label',
  RENAME_LABEL: 'Rename a label',

  UPDATE_LAST_CHANGED_AT: 'Update the last changed at date',

  DELETE_ALL_LOCAL_DATA: 'Delete all local data',
  RESTORE_DATA_FROM_BACKUP: 'Restore data from backup',

  PUSH_MESSAGE: 'Push message into stack',
  SHIFT_MESSAGE: 'Remove the first message from stack',
}
