export default function (number) {
  switch (true) {
    case [11, 12, 13].includes(number % 100):
      return `${number}th`;
    case number % 10 === 1:
      return `${number}st`;
    case number % 10 === 2:
      return `${number}nd`;
    case number % 10 === 3:
      return `${number}rd`;
    default:
      return `${number}th`;
  }
}