<template>
    <form @submit.prevent="save"
          class="border-2 border-gray-400 dark:border-gray-500 focus-within:border-blue-500
            rounded-lg"
          @keydown.enter="save"
          @keydown.esc="$emit('cancelled')"
          @keydown.up="moveUp"
          @keydown.down="moveDown"
          @keydown.left="moveLeft"
          @keydown.right="moveRight"
    >
        <span class="flex flex-grow flex-shrink-0
                justify-center items-center
                my-4"
        >
            {{ input ? input : 'no due date set' }}
        </span>

        <div class="flex justify-between mb-6">
            <span class="flex flex-grow ml-4 mr-2">
                <span class="border-2 border-gray-400 dark:border-gray-500
                                outline-none
                                focus:border-blue-500
                                hover:bg-blue-200 dark:hover:bg-blue-400
                                rounded-l
                                p-2
                                cursor-pointer
                                focus:bg-blue-300"
                      @click="decMonth"
                      @keydown.space="decMonth"
                      ref="decMonth"
                      data-ref="decMonth"
                      tabIndex="0"
                >&lt;</span>

                <span class="flex-grow text-center border-t-2 border-b-2 border-gray-400 dark:border-gray-500 p-2">
                    {{ new Intl.DateTimeFormat(undefined, {month: 'long'}).format(date) }}
                </span>

                <span class="border-2 border-gray-400 dark:border-gray-500
                                hover:bg-blue-200 dark:hover:bg-blue-400
                                outline-none
                                focus:border-blue-500
                                rounded-r
                                p-2
                                cursor-pointer
                                focus:bg-blue-300"
                      @click="incMonth"
                      @keydown.space="incMonth"
                      ref="incMonth"
                      data-ref="incMonth"
                      tabIndex="0"
                >&gt;</span>
            </span>

            <span class="flex flex-grow ml-2 mr-4">
                <span class="border-2 border-gray-400 dark:border-gray-500
                                hover:bg-blue-200 dark:hover:bg-blue-400
                                outline-none
                                focus:border-blue-500
                                rounded-l
                                p-2
                                cursor-pointer
                                focus:bg-blue-300"
                      @click="decYear"
                      @keydown.space="decYear"
                      ref="decYear"
                      data-ref="decYear"
                      tabIndex="0"
                >&lt;</span>

                <span class="flex-grow text-center border-t-2 border-b-2 border-gray-400 dark:border-gray-500 p-2">
                    {{ date.getFullYear() }}
                </span>

                <span class="border-2 border-gray-400 dark:border-gray-500
                                hover:bg-blue-200 dark:hover:bg-blue-400
                                outline-none
                                focus:border-blue-500
                                rounded-r
                                p-2
                                cursor-pointer
                                focus:bg-blue-300"
                      @click="incYear"
                      @keydown.space="incYear"
                      ref="incYear"
                      data-ref="incYear"
                      tabIndex="0"
                >&gt;</span>
            </span>
        </div>

        <div class="grid grid-cols-7
                border-inherit border-b-2
                p-4"
        >
            <div v-for="(dow, idx) in ['S', 'M', 'T', 'W', 'T', 'F', 'S']"
                 :key="idx"
                 class="border-2 border-gray-400 dark:border-gray-500
                            bg-gray-300 dark:bg-gray-900
                            text-center
                            py-3 narrow:py-2"
                 :class="{
                   'border-t-0': idx > 7,
                   'border-l-0': idx % 7 !== 0
                   }"
            >
                {{ dow }}
            </div>
            <div v-for="(day, idx) in Array.from(Array(daysInMonth + firstDayOfMonth).keys(), d => d + 1)"
                 :key="idx"
                 class="border-2 border-t-0 border-gray-400 dark:border-gray-500
                            hover:bg-blue-200 dark:hover:bg-blue-400
                            outline-none
                            text-center
                            py-3 narrow:py-2
                            cursor-pointer
                            focus:bg-blue-300"
                 :class="{
                   'border-l-0': day % 7 !== 1,
                   'bg-blue-600': day === date.getDate() + firstDayOfMonth,
                   'text-gray-100': day === date.getDate() + firstDayOfMonth,
                 }"
                 @click="(day > firstDayOfMonth) && pick(day - firstDayOfMonth)"
                 @keydown.space="(day > firstDayOfMonth) && pick(day - firstDayOfMonth)"
                 :tabIndex="0"
                 :ref="`day-${idx}`"
                 :data-ref="`day-${idx}`"
            >{{ day > firstDayOfMonth ? day - firstDayOfMonth : '' }}
            </div>
        </div>

        <div class="flex w-full border-inherit">
            <submit-button class="flex-grow border-0"></submit-button>

            <button @click="$emit('changed', 0)"
                    type="button"
                    class="flex-grow
                            hover:bg-blue-200 dark:hover:bg-blue-400
                            border-inherit border-l-2
                            px-2 py-1"
            >
                Clear
            </button>

            <button @click="$emit('cancelled')"
                    type="button"
                    class="flex-grow
                            hover:bg-blue-200 dark:hover:bg-blue-400
                            border-inherit border-l-2
                            px-2 py-1"
            >
                Cancel
            </button>
        </div>

        <div v-if="error" class="text-red-700 p-2 border-inherit border-t-2">
            {{ error }}
        </div>
    </form>
</template>

<script>
import SubmitButton from "@/components/form/SubmitButton";
import {addMonthsToDate, subMonthsFromDate} from "@/utils/date";
export default {
  components: {SubmitButton},
  emits: ['cancelled', 'changed'],
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      date: this.value.getTime() > 0 ? this.value : new Date(),
      changed: false,
      currentFocus: null,
      error: '',
    };
  },
  computed: {
    input() {
      if (this.changed === false && this.value.getTime() === 0) {
        return '';
      }

      return new Intl.DateTimeFormat().format(this.date);
    },
    daysInMonth() {
      return new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate();
    },
    firstDayOfMonth() {
      return new Date(this.date.getFullYear(), this.date.getMonth(), 1).getDay();
    }
  },
  methods: {
    incMonth() {
      this.changed = true;

      this.date = new Date(addMonthsToDate(1, this.date));
    },
    decMonth() {
      this.changed = true;

      this.date = new Date(subMonthsFromDate(1, this.date));
    },
    incYear() {
      this.date.setFullYear(this.date.getFullYear() + 1);
      this.changed = true;

      this.date = new Date(this.date);
    },
    decYear() {
      this.date.setFullYear(this.date.getFullYear() - 1);
      this.changed = true;

      this.date = new Date(this.date);
    },
    pick(day) {
      this.date.setDate(day);
      this.changed = true;

      this.date = new Date(this.date);
    },
    focusChanged(e) {
      this.currentFocus = e.target?.dataset?.ref;
    },
    moveUp() {
      if (this.currentFocus === null) {
        return;
      }

      if (['incMonth', 'decMonth', 'incYear', 'decYear'].includes(this.currentFocus)) {
        return;
      }

      if (this.currentFocus.substr(0, 4) === 'day-') {
        const idx = this.currentFocus.substr(4);

        if (['0', '1'].includes(idx)) {
          this.$refs['decMonth'].focus();
          return;
        }

        if (['2', '3'].includes(idx)) {
          this.$refs['incMonth'].focus();
          return;
        }

        if (['4', '5'].includes(idx)) {
          this.$refs['decYear'].focus();
          return;
        }

        if (idx === '6') {
          this.$refs['incYear'].focus();
          return;
        }

        this.$refs[`day-${idx-7}`][0].focus();
      }
    },
    moveDown() {
      if (this.currentFocus === null) {
        return;
      }

      if (this.currentFocus === 'decMonth') {
        this.$refs['day-0'][0].focus();
        return;
      }

      if (this.currentFocus === 'incMonth') {
        this.$refs['day-2'][0].focus();
        return;
      }

      if (this.currentFocus === 'decYear') {
        this.$refs['day-4'][0].focus();
        return;
      }

      if (this.currentFocus === 'incYear') {
        this.$refs['day-6'][0].focus();
        return;
      }

      if (this.currentFocus.substr(0, 4) === 'day-') {
        const idx = parseInt(this.currentFocus.substr(4)) + 7;

        if (idx < this.daysInMonth + this.firstDayOfMonth) {
          this.$refs[`day-${idx}`][0].focus();
        }
      }
    },
    moveLeft() {
      if (this.currentFocus === null) {
        return;
      }

      if (this.currentFocus === 'decMonth') {
        return;
      }

      if (this.currentFocus === 'incMonth') {
        this.$refs['decMonth'].focus();
        return;
      }

      if (this.currentFocus === 'decYear') {
        this.$refs['incMonth'].focus();
        return;
      }

      if (this.currentFocus === 'incYear') {
        this.$refs['decYear'].focus();
        return;
      }

      if (this.currentFocus.substr(0, 4) === 'day-') {
        const idx = parseInt(this.currentFocus.substr(4));
        if (idx <= this.firstDayOfMonth) {
          this.$refs[`day-${this.daysInMonth + this.firstDayOfMonth - 1}`][0].focus();
          return;
        }

        this.$refs[`day-${idx - 1}`][0].focus();
      }
    },
    moveRight() {
      if (this.currentFocus === null) {
        return;
      }

      if (this.currentFocus === 'decMonth') {
        this.$refs['incMonth'].focus();
        return;
      }

      if (this.currentFocus === 'incMonth') {
        this.$refs['decYear'].focus();
        return;
      }

      if (this.currentFocus === 'decYear') {
        this.$refs['incYear'].focus();
        return;
      }

      if (this.currentFocus === 'incYear') {
        return;
      }

      if (this.currentFocus.substr(0, 4) === 'day-') {
        const idx = parseInt(this.currentFocus.substr(4));

        if (idx < this.firstDayOfMonth) {
          this.$refs[`day-${this.firstDayOfMonth}`][0].focus();
          return;
        }

        if (idx + 1 >= this.firstDayOfMonth + this.daysInMonth) {
          this.$refs[`day-${this.firstDayOfMonth}`][0].focus();
          return;
        }

        this.$refs[`day-${idx + 1}`][0].focus();
      }
    },
    save() {
      if (this.date.toString() === 'Invalid Date') {
        this.error = 'Please select or enter a date in the format day/month/year';

        return;
      }

      this.$emit('changed', this.date.getTime());
    },
  },
  mounted() {
    document.addEventListener('focusin', this.focusChanged);
    this.$nextTick(() => {
      this.$refs[`day-${this.date.getDate() + this.firstDayOfMonth - 1}`][0].focus();
    })
  },
  beforeUnmount() {
    document.addEventListener('focusin', this.focusChanged);
  }
}
</script>