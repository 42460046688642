export const dayNames = [
  {short: 'Sun', long: 'Sunday'},
  {short: 'Mon', long: 'Monday'},
  {short: 'Tue', long: 'Tuesday'},
  {short: 'Wed', long: 'Wednesday'},
  {short: 'Thu', long: 'Thursday'},
  {short: 'Fri', long: 'Friday'},
  {short: 'Sat', long: 'Saturday'},
];

export const monthNames = [
  {short: 'Jan', long: 'January'},
  {short: 'Feb', long: 'February'},
  {short: 'Mar', long: 'March'},
  {short: 'Apr', long: 'April'},
  {short: 'May', long: 'May'},
  {short: 'Jun', long: 'June'},
  {short: 'Jul', long: 'July'},
  {short: 'Aug', long: 'August'},
  {short: 'Sep', long: 'September'},
  {short: 'Oct', long: 'October'},
  {short: 'Nov', long: 'November'},
  {short: 'Dec', long: 'December'},
];

export const ymd = (date) => {
  return date.getFullYear()*10000 + date.getMonth()*100 + date.getDate();
}

export const isLeapYear = (date) => {
  const year = date.getFullYear();

  if (year % 400 === 0) {
    return false;
  }

  return year % 4 === 0;
}

export const addMonthsToDate = (numberOfMonths, date) => {
  const numberOfYears = Math.floor((numberOfMonths + date.getMonth()) / 12);
  const newMonth = (numberOfMonths + date.getMonth()) % 12;

  const newDate = new Date(date);
  newDate.setFullYear(date.getFullYear() + numberOfYears);

  return setMonth(newDate, newMonth);
}

export const subMonthsFromDate = (numberOfMonths, date) => {
  const numberOfYears = Math.floor((date.getMonth() - numberOfMonths) / 12);
  const newMonth = (12 + (date.getMonth() - numberOfMonths) % 12) %12;

  const newDate = new Date(date);
  newDate.setFullYear(date.getFullYear() + numberOfYears);

  return setMonth(newDate, newMonth);
}

export const setMonth = (date, newMonth) => {
  date.setMonth(newMonth);

  if (date.getMonth() > newMonth) {
    date.setMonth(date.getMonth() - 1);

    switch(date.getMonth()) {
      case 1:
        // February
        isLeapYear(date) ? date.setDate(29) : date.setDate(28);
        break;

      default:
        // April, June, September, November
        date.setDate(30);
    }
  }

  return date;
}
