import { createStore } from 'vuex';
import { VuexPersistence } from 'vuex-persist';
import localforage from 'localforage';

import { actions } from '@/store/actions';
import { getters } from "@/store/getters";
import { mutations } from "@/store/mutations";

export { actions, getters, mutations };

const vuexPersist = new VuexPersistence({
  key: 'crossitoff-vuex',
  // localforage uses IndexedDB which is asynchronous
  storage: localforage,
  asyncStorage: true,
  // whitelist which specific state values to be persisted
  reducer: (state) => ({
    currentTodoUuid: state.currentTodoUuid,
    // can't seem to serialize a straight array into IndexedDB
    // https://github.com/championswimmer/vuex-persist/issues/232
    meta: JSON.parse(JSON.stringify(state.meta)),
    settings: JSON.parse(JSON.stringify(state.settings)),
    todos: JSON.parse(JSON.stringify(state.todos)),
  }),
});

export default createStore({
  state: {
    currentTodoUuid: null,
    todos: [],
    settings: {
      labels: [
        {
          uuid: 'label-1',
          name: 'Home',
          color: '#ef4444'
        },
        {
          uuid: 'label-2',
          name: 'Work',
          color: '#f59e0b'
        },
        {
          uuid: 'label-3',
          name: 'School',
          color: '#84cc16'
        },
      ]
    },
    meta: {},
    messages: [],
  },
  actions,
  getters,
  mutations,

  modules: {},
  plugins: [
    vuexPersist.plugin,
  ],
});
