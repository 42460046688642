<template>
    <div @click="editing = !editing && editable"
         class="grid grid-cols-todo-details outline-none
                py-2 px-1 narrow:p-0
                border-2 border-transparent rounded-lg
                focus:border-gray-400 focus:dark:border-gray-500"
         :class="{ 'cursor-pointer': editable }"
         :tabindex="editable ? 0 : -1"
         @keydown.space.prevent="editing = editable && true"
         @keydown.enter.prevent="editing = editable && true"
         ref="field"
    >
        <span class="py-1 text-blue-600 dark:text-blue-300 font-bold">Due</span>
        <span v-if="!editing">
            <span :class="dueAtStyles" class="inline-block px-2 py-1 rounded-xl">
                {{ dueAtDisplay }}
            </span>
        </span>
    </div>

    <div v-if="editing" class="mb-2">
        <date-input name="dueAt"
                    :value="dueAt"
                    @changed="save"
                    @cancelled="editing = false"
                    @keydown.esc.prevent="nextTick(() => field.focus())"
        ></date-input>
    </div>
</template>

<script setup>
import { computed, nextTick, ref, toRefs, watch } from 'vue';
import {ymd} from '@/utils/date';

import DateInput from '@/components/DateInput';

const emit = defineEmits(['changed']);

const props = defineProps({
  close: {
    type: Boolean,
    required: true,
  },
  dueAt: {
    type: Date,
    required: true,
  },
  editable: {
    type: Boolean,
    default: true,
  }
});

const { close, dueAt } = toRefs(props);

const field = ref(null);
const editing = ref(false);

const dueAtDisplay = computed(() => {
  if (dueAt.value.getTime() === 0) {
    return 'Whenever';
  }

  return new Intl.DateTimeFormat('default', {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'}).format(dueAt.value);
});

const dueAtStyles = computed(() => {
  if (dueAt.value.getTime() === 0) {
    return {};
  }

  return {
    'bg-red-500': ymd(new Date()) > ymd(dueAt.value),
    'bg-amber-500': ymd(new Date()) === ymd(dueAt.value),
    'text-gray-100': ymd(new Date()) >= ymd(dueAt.value),
  };
});

const save = (dueAt) => {
  emit('changed', dueAt);
  editing.value = false;
};

watch(close, (currentValue) => {
  if (currentValue) {
    editing.value = false;
  }
});
</script>