<template>
    <svg viewBox="0 0 320 320"
         xmlns="http://www.w3.org/2000/svg"
    >
        <title>{{ title }}</title>
        <path :fill="fill"
              :stroke="stroke"
              stroke-width="30"
              stroke-linejoin="round"
              stroke-linecap="round"
              d="M  15  15
                   160 140
                   305  15
                   180 160
                   305 305
                   160 180
                    15 305
                   140 160
                    15  15"
        />
    </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    stroke: {
      type: String,
      default: '#ffffff',
    },
    fill: {
      type: String,
      default: '#ffffff',
    }
  }
}
</script>