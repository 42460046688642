<template>
    <div @keydown.esc.prevent="open = false;"
         class="mt-4 w-full rounded-lg border-2 border-gray-400 rounded"
    >
        <div @click="open = !open"
             @keydown.enter.prevent="open = true;"
             @keydown.space.prevent="open = true;"
             :tabindex="open ? -1 : 0"
             ref="todosFilter"
             class="flex justify-center px-3 py-2 hover:bg-blue-400 cursor-pointer rounded text-blue-600 hover:text-gray-900"
        >
            <span>Filter</span>

            <svg viewBox="0 0 320 320"
                 xmlns="http://www.w3.org/2000/svg"
                 class="inline w-5 ml-1"
            >
                <line fill="currentColor"
                      stroke="currentColor"
                      stroke-width="30"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      x1="40"
                      y1="40"
                      x2="280"
                      y2="40"
                />
                <line fill="currentColor"
                      stroke="currentColor"
                      stroke-width="30"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      x1="40"
                      y1="40"
                      x2="160"
                      y2="160"
                />
                <line fill="currentColor"
                      stroke="currentColor"
                      stroke-width="30"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      x1="280"
                      y1="40"
                      x2="160"
                      y2="160"
                />
                <line fill="currentColor"
                      stroke="currentColor"
                      stroke-width="30"
                      stroke-linejoin="round"
                      stroke-linecap="round"
                      x1="160"
                      y1="160"
                      x2="160"
                      y2="280"
                />
            </svg>
        </div>

        <form v-if="open"
              class="grid grid-cols-[max-content_1fr] gap-4 px-3 py-2"
              @click.stop.prevent
        >
            <label for="search"
                   class="my-auto text-blue-600 dark:text-blue-300 font-bold"
            >
                Name
            </label>

            <input id="search"
                   ref="search"
                   type="text"
                   inputmode="text"
                   autocomplete="off"
                   @keydown="typeahead"
                   @keyup.prevent
                   class="block
                            dark:bg-gray-300
                            narrow:text-3xl dark:text-gray-900
                            border-gray-400 border-2
                            outline-none
                            p-1
                            rounded-lg
                            w-full"
            />

            <label id="labelForLabelsList"
                   class="my-auto text-blue-600 dark:text-blue-300 font-bold"
            >
                Labels
            </label>

            <todo-labels
                    aria-labelledby="labelForLabelsList"
                    :availableLabels="[
                      ...store.state.settings.labels,
                      {
                        uuid: 'unlabelled',
                        name: 'Unlabelled',
                        color: '#999999',
                      }
                    ]"
                    :focusOnOpen="false"
                    :selectedLabels="selectedLabels"
                    @changed="(selected) => emit('filterByLabels', selected)"
                    class="border-b-0 w-[50%]"
            ></todo-labels>
        </form>
    </div>
</template>

<script setup>
import {nextTick, ref, watch} from 'vue';
import {useStore} from 'vuex';

import TodoLabels from '@/components/TodoLabels';

const store = useStore();

const emit = defineEmits(['filterByName', 'filterByLabels']);

const search = ref(null);
const todosFilter = ref(null);

const open = ref(false);
const selectedLabels = ref([]);

const typeaheadTimer = ref(null);

watch(open, (currentValue) => {
  if (currentValue) {
    nextTick(() => {
      search.value.focus();
    });
  } else {
    emit('filterByName', '');
    emit('filterByLabels', []);
    nextTick(() => {
      todosFilter.value.focus();
      selectedLabels.value = [];
    })
  }
})

const typeahead = (event) => {
  const {key, altKey, ctrlKey, metaKey} = event;

  if (typeaheadTimer.value) {
    window.clearTimeout(typeaheadTimer.value);
  }

  // if the user starts typing
  if (key === 'Unidentified'
    || key === 'Backspace'
    || key === 'Clear'
    || (key.length === 1 && !(altKey || ctrlKey || metaKey))
    || (['v', 'V'].includes(key) && ctrlKey)
  ) {
    typeaheadTimer.value = window.setTimeout(() => {
      emit('filterByName', search.value.value);
    }, 500);
  }
};
</script>