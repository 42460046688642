<template>
    <form @submit.prevent="save" @keydown.enter.prevent="save">
        <div class="flex w-full max-w-lg mb-4 focus-within:shadow-lg narrow:flex-col narrow:text-xl narrow:max-w-none">
            <label for="label-name"
                   class="bg-gray-300 dark:bg-gray-900
                            border-2 border-gray-400 narrow:border-b-0 wide:border-r-0 dark:border-gray-500
                            px-2 py-1
                            wide:rounded-l narrow:rounded-t
                            whitespace-nowrap"
            >
                Name
            </label>

            <input id="label-name"
                   ref="label-name"
                   type="text"
                   inputmode="text"
                   v-model="name"
                   class="flex-grow
                            dark:bg-gray-300
                            narrow:text-3xl dark:text-gray-900
                            border-2 border-gray-400 dark:border-gray-500
                            p-1
                            wide:rounded-r narrow-rounded-b
                            narrow:w-full"
                   autocomplete="off"
            />
        </div>

        <div class="mb-4 flex w-full max-w-lg focus-within:shadow-lg narrow:flex-col narrow:text-xl narrow:max-w-none">
            <label for="label-color"
                   class="bg-gray-300 dark:bg-gray-900
                            border-2 border-gray-400 narrow:border-b-0 wide:border-r-0 dark:border-gray-500
                            px-2 py-1
                            wide:rounded-l narrow:rounded-t
                            whitespace-nowrap"
            >
                Color
            </label>

            <ul id="label-color"
                tabindex="0"
                role="listbox"
                inputmode="text"
                class="flex justify-between gap-2
                        flex-grow
                        dark:bg-gray-300
                        narrow:text-3xl dark:text-gray-900
                        border-2 border-gray-400 dark:border-gray-500
                        p-1
                        wide:rounded-r narrow-rounded-b
                        narrow:w-full narrow:h-10"
                @keydown.right.prevent = "colorIndex += (colorIndex < availableColors.length - 1 ? 1 : 0)"
                @keydown.down.prevent = "colorIndex += (colorIndex < availableColors.length - 1 ? 1 : 0)"
                @keydown.left.prevent = "colorIndex -= (colorIndex > 0 ? 1 : 0)"
                @keydown.up.prevent = "colorIndex -= (colorIndex > 0 ? 1 : 0)"
            >
                <li v-for="(option, i) in availableColors"
                    :id="option"
                    :key="option"
                    :style="{ backgroundColor: option }"
                    class="flex flex-grow
                            h-full
                            rounded
                            justify-center
                            items-center"
                    @click="colorIndex = i"
                >
                    <tick v-if="option === color"
                        alt="/"
                        class="w-5 h-5"
                    />
                </li>
            </ul>
        </div>

        <div class="mb-4 narrow:flex narrow:w-full">
            <submit-button class="flex-grow rounded-l"></submit-button>

            <button @click="$emit('cancelled')"
                    type="button"
                    class="flex-grow
                            hover:bg-blue-200 dark:hover:bg-blue-400
                            border-2 border-gray-400 border-l-0 dark:border-gray-500
                            px-2 py-1
                            rounded-r"
            >
                Cancel
            </button>
        </div>

        <p v-if="error" class="text-red-700 mb-4">
            {{ error }}
        </p>
    </form>
</template>

<script>
import SubmitButton from '@/components/form/SubmitButton.vue';
import Tick from "@/components/ui/Tick";

export default {
  components: {
    SubmitButton,
    Tick
  },
  emits: [
    'changed',
    'cancelled',
  ],
  props: {
    label: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      color: this.label.color,
      name: this.label.name,

      error: '',

      availableColors: [
        '#ef4444',
        '#f59e0b',
        '#84cc16',
        '#10b981',
        '#06b6d4',
        '#3b82f6',
        '#8b5cf6',
        '#d946ef',
      ],

      colorIndex: 0,
    }
  },

  methods: {
    save() {
      if (this.name === '') {
        this.error = 'Sorry, but you must give your label a name.';
        return;
      }

      if (this.color === '') {
        this.error = 'Sorry, but you must give your label a color.';
        return;
      }

      this.$emit('changed', {
        uuid: this.label.uuid,
        name: this.name,
        color: this.color,
      });
    }
  },

  mounted() {
    this.colorIndex = this.availableColors.findIndex((color) => {
      return color === this.label.color;
    });

    this.$nextTick(() => {
      this.$refs['label-name'].focus();
    });
  },

  watch: {
    colorIndex(currentValue) {
  this.color = this.availableColors[currentValue];
    }
  }
}
</script>