<template>
    <h1 class="mt-2">Share</h1>

    <form class="border-2 border-gray-400 dark:border-gray-500
                    rounded-lg
                    outline-4 outline-transparent
                    focus-within:border-blue-500"
          @submit.prevent="copyToClipboard">
        <textarea id="description"
                  ref="textarea"
                  v-model="shareText"
                  @keyup="checkLength"
                  class="block
                            dark:bg-gray-300
                            narrow:text-xl dark:text-gray-900
                            border-inherit border-b-2
                            outline-none
                            h-96
                            p-1
                            rounded-t-lg
                            w-full
                            overflow-y-auto"
        ></textarea>

        <p class="rounded-t-lg
                    m-0 p-2
                    border-inherit border-b-2
                    text-right"
           role="status"
           aria-live="polite"
        >
            {{ numChars }} characters
        </p>

        <share-button class="bg-blue-600 hover:bg-blue-200 dark:hover:bg-blue-400
                        border-0
                        rounded-t-none
                        w-full
                        text-white hover:text-gray-900 dark:hover:text-white"
        ></share-button>
    </form>

    <div
         class="flex justify-center
                    my-6 p-3
                    border-t border-b border-blue-600 bg-blue-100
                    text-gray-900"
         :style="copiedMessageStyle"
    >
        <p>
            Copied to clipboard.
        </p>
    </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head';

import ShareButton from '@/components/ShareButton';

const route = useRoute();
const store = useStore();

const copied = ref(false);
const filter = ref(route.params.filter ?? '');
const numChars = ref(0);
const shareText = ref('');
const textarea = ref(null);
const typingTimer = ref(null);

const copiedMessageStyle = computed(() => {
  return {
    opacity: copied.value ? 1 : 0,
    transition: copied.value ? 'none' : 'opacity 2s',
  }
});

const copyToClipboard = () => {
    navigator.clipboard.writeText(textarea.value.value);
    copied.value = true;

    window.setTimeout(() => copied.value = false, 2000);
};

const checkLength = () => {
  if (typingTimer.value) {
    clearTimeout(typingTimer.value);
  }

  typingTimer.value = setTimeout(() => {
    numChars.value = shareText.value.length;
  }, 500);
}

onMounted(() => {
  nextTick(() => {
    let todos = [];
    let title = '';

    switch (filter.value) {
      case 'yesterday':
        title = 'Yesterday I crossed these to-dos off my list:\n';
        todos = store.getters.thingsThatHaveBeenDoneYesterday;
        break;
      case 'last-week':
        title = 'Last week I crossed these to-dos off my list:\n';
        todos = store.getters.thingsThatHaveBeenDoneLastWeek;
        break;
      case 'last-seven-days':
        title = 'In the last seven days I crossed these to-dos off my list:\n';
        todos = store.getters.thingsThatHaveBeenDoneSevenDaysAgo;
        break;
      default:
        title = 'Today I crossed these to-dos off my list:\n';
        todos = store.getters.thingsThatHaveBeenDoneToday;
    }

    shareText.value = [
        title,
        ...todos.map((todo) => {
          return `  ☑  ${todo.name}`;
        }),
       '\nhttps://crossitoff.app',
    ].join('\n');

    numChars.value = shareText.value.length;
    textarea.value.focus();
  });
});

useHead({
  title: 'Share | Cross It Off'
});
</script>