<template>
    <button type="submit"
            class="bg-blue-600 hover:bg-blue-200 dark:hover:bg-blue-400 px-2 py-1
                border-2 border-gray-400 dark:border-gray-500
                px-2 py-1
                text-white hover:text-gray-900 dark:hover:text-white"
            @clicked="$emit('clicked')"
    >
        {{ text }}
    </button>
</template>

<script>
export default {
  emits: ['clicked'],
  props: {
    text: {
      type: String,
      default: 'Save',
    },
  },
}
</script>