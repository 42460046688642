<template>
    <div>
        <h1 v-if="!editing"
            class="m-0 text-blue-600 dark:text-blue-300 outline-none
                   px-1 narrow:px-0
                   border-2 border-transparent rounded-lg
                   focus:border-gray-400 focus:dark:border-gray-500"
            :class="{ 'cursor-pointer': editable }"
            @click="editing = editable && true"
            @keydown.space.prevent="editing = editable && true"
            @keydown.enter.prevent="editing = editable && true"
            :tabindex="editable ? 0 : -1"
            ref="nameDisplay"
        >{{ name }}</h1>

        <form v-if="editing"
              @submit.prevent="save"
              @keydown.esc.prevent="editing = false; nextTick(() => nameDisplay.focus())"
              class="block
                    border-2 border-gray-400 dark:border-gray-500
                    rounded-lg
                    focus-within:border-blue-500">
            <input id="name"
                   ref="nameInput"
                   type="text"
                   inputmode="text"
                   :value="name"
                   class="block
                            dark:bg-gray-300
                            text-4xl dark:text-gray-900
                            border-inherit border-b-2
                            outline-none
                            p-1
                            rounded-t-lg
                            w-full"
                   autocomplete="off"
            />

            <div class="flex w-full border-inherit">
                <submit-button class="flex-grow border-0"></submit-button>

                <button @click="editing = false"
                        type="button"
                        class="flex-grow
                                hover:bg-blue-200 dark:hover:bg-blue-400
                                border-inherit border-l-2
                                px-2 py-1"
                >
                    Cancel
                </button>
            </div>

            <p v-if="error" class="text-red-700 mt-8">
                {{ error }}
            </p>
        </form>
    </div>
</template>

<script setup>
import { nextTick, ref, toRefs, watch } from 'vue';

import SubmitButton from "@/components/form/SubmitButton";

const emit = defineEmits(['changed']);

const props = defineProps({
  close: {
    type: Boolean,
    required: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const { close, editable, name } = toRefs(props);

const nameDisplay = ref(null);
const editing = ref(false);
const error = ref('');
const nameInput = ref(null);

const save = () => {
  if (nameInput.value.value === '') {
    error.value = 'Sorry, but you must give your to-do a name.';
    return;
  }

  emit('changed', nameInput.value.value);
  editing.value = false;
}

watch(editing, (currentValue) => {
  if (currentValue) {
    nextTick(() => {
      nameInput.value.focus();
    })
  }
});

watch(close, (currentValue) => {
  if (currentValue) {
    editing.value = false;
  }
});
</script>