<template>
    <nav class="flex gap-10 justify-center narrow:justify-around overflow-x-none overflow-y-auto
            border-t border-gray-900 dark:border-gray-500
            bg-gray-200 dark:bg-gray-700
            text-gray-900 dark:text-gray-100"
         :style="styles"
    >
        <router-link :to="{name: 'Todo'}"
                     class="h-14 w-14 p-3 narrow:w-12 narrow:h-12 border-x hover:bg-blue-400"
                     :class="{
                        'bg-gray-300': route.name === 'Todo',
                        'dark:bg-blue-700': route.name === 'Todo',
                        'border-transparent': route.name !== 'Todo',
                        'border-gray-900': route.name === 'Todo',
                        'dark:border-gray-500': route.name === 'Todo',
                     }"
        >
            <home-button
                    class="w-full stroke-current"
            ></home-button>
        </router-link>

        <router-link :to="{name: 'Todos'}"
                     class="h-14 w-14 p-3 narrow:w-12 narrow:h-12 border-x hover:bg-blue-400"
                     :class="{
                        'bg-gray-300': route.name === 'Todos',
                        'dark:bg-blue-700': route.name === 'Todos',
                        'border-transparent': route.name !== 'Todos',
                        'border-gray-900': route.name === 'Todos',
                        'dark:border-gray-500': route.name === 'Todos',
                     }"
        >
            <list-button
                    class="w-full stroke-current"
            ></list-button>
        </router-link>

        <router-link :to="{name: 'Settings'}"
                     class="h-14 w-14 p-3 narrow:w-12 narrow:h-12 border-x hover:bg-blue-400"
                     :class="{
                        'bg-gray-300': route.name === 'Settings',
                        'dark:bg-blue-700': route.name === 'Settings',
                        'border-transparent': route.name !== 'Settings',
                        'border-gray-900': route.name === 'Settings',
                        'dark:border-gray-500': route.name === 'Settings',
                     }"
        >
            <settings-button
                    class="w-full stroke-current"
            ></settings-button>
        </router-link>

        <router-link :to="{name: 'AddTodo'}"
                     class="h-14 w-14 p-3 narrow:w-12 narrow:h-12 border-x hover:bg-blue-400"
                     :class="{
                        'bg-gray-300': route.name === 'AddTodo',
                        'dark:bg-blue-700': route.name === 'AddTodo',
                        'border-transparent': route.name !== 'AddTodo',
                        'border-gray-900': route.name === 'AddTodo',
                        'dark:border-gray-500': route.name === 'AddTodo',
                     }"
        >
            <add-button
                    title="Add something that needs doing"
                    class="w-full stroke-current"
            ></add-button>
        </router-link>

        {{ events }}
    </nav>
</template>

<script setup>
import { computed, onMounted, ref} from 'vue';
import { useRoute } from 'vue-router';

import AddButton from '@/components/buttons/Add';
import HomeButton from '@/components/buttons/Home';
import ListButton from '@/components/buttons/List';
import SettingsButton from '@/components/buttons/Settings';

const route = useRoute();

const screenHeight = ref(null);
const inputFocused = ref(false);
const keyboardOpen = ref(false);
const events = ref('');

const styles = computed(() => {
  return {
    display: keyboardOpen.value ? 'none' : 'flex',
  }
});

onMounted(() => {
  if (screenHeight.value === null) {
    screenHeight.value = window.outerHeight;
  }

  window.addEventListener('focusin', (event) => {
    if (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA') {
      inputFocused.value = true;
    }
  });

  window.addEventListener('resize', () => {
    if (screenHeight.value > window.outerHeight && inputFocused.value) {
      keyboardOpen.value = true;
    } else {
      screenHeight.value = window.outerHeight;
      keyboardOpen.value = false;
    }
    inputFocused.value = false;
  });

  window.addEventListener('focusout', () => {
    keyboardOpen.value = false;
  });
});
</script>
