<template>
    <h1 class="mt-0">All done!</h1>

    <p class="mt-8 transition-opacity duration-1000"
       :class="opacity(1)"
    >
        That's it - you're all done.
    </p>

    <p class="mt-4"
    >
        <span class="transition-opacity duration-1000"
              :class="opacity(2)">Unless... </span>
        <span class="transition-opacity duration-1000"
              :class="opacity(3)">you want to do more?</span>
    </p>

    <new-todo class="transition-opacity duration-1000"
              :class="opacity(4)"
              @save="router.push({name: 'Todo'})"
    ></new-todo>
</template>

<script setup>
import { nextTick, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useHead } from '@vueuse/head';

import NewTodo from '@/components/NewTodo';

import isEmpty from '@/utils/isEmpty';

const router = useRouter();
const store = useStore();

const revealStep = ref(0);

const opacity = (step) => {
  if (revealStep.value > step) {
    return { 'opacity-100': true };
  }

  return { 'opacity-0': true };
};

const reveal = () => {
  revealStep.value += 1;

  switch (revealStep.value) {
    case 1:
    case 2:
    case 3:
    case 4:
      setTimeout(reveal, 1000);
      break;

    default:
    // no-op
  }
};

onMounted(() => {
    if (store.state.currentTodoUuid) {
      router.push({ name: 'Todo' });
      return;
    }
    if (isEmpty(store.state.todos)) {
      router.push({ name: 'Welcome' });
      return;
    }
    nextTick(() => {
      reveal()
    });
});

useHead({
  title: 'Crossed It All Off'
});
</script>
