<template>
    <svg viewBox="0 0 320 320"
         xmlns="http://www.w3.org/2000/svg"
         class="stroke-current"
    >
        <title>{{ title }}</title>
        <line x1="160"
              y1="15"
              x2="160"
              y2="305"
              fill="none"
              stroke-width="30"
              stroke-linejoin="round"
              stroke-linecap="round"/>

        <line x1="15"
              y1="160"
              x2="305"
              y2="160"
              fill="none"
              stroke-width="30"
              stroke-linejoin="round"
              stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  }
}
</script>