<template>
    <button class="flex justify-center border-2 border-gray-400 px-3 py-2 hover:bg-blue-400 rounded">
        <span>Share</span>

        <svg viewBox="0 0 320 320"
             xmlns="http://www.w3.org/2000/svg"
             class="inline w-5 ml-1 mt-1"
        >
            <line fill="currentColor"
                  stroke="currentColor"
                  stroke-width="30"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                  x1 = "240"
                  y1 = "80"
                  x2 = "80"
                  y2 = "160"
            />
            <line fill="currentColor"
                  stroke="currentColor"
                  stroke-width="30"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                  x1 = "240"
                  y1 = "240"
                  x2 = "80"
                  y2 = "160"
            />
            <circle fill="currentColor"
                    stroke="currentColor"
                    cx="240"
                    cy="80"
                    r="40"
            />
            <circle fill="currentColor"
                    stroke="currentColor"
                    cx="240"
                    cy="240"
                    r="40"
            />
            <circle fill="currentColor"
                    stroke="currentColor"
                    cx="80"
                    cy="160"
                    r="40"
            />
        </svg>
    </button>
</template>