<template>
    <div>
        <h2 @click="expanded = !expanded"
            class="mt-4 p-2 cursor-pointer hover:bg-blue-300"
        >
            <input type="image"
                   :src="expanded ? '/img/ui/chevron-up.svg' : '/img/ui/chevron-down.svg'"
                   class="text-gray-900 dark:text-gray-100
                            stroke-current
                            w-6
                            mr-2"
                   tabindex="0"
                   aria-controls="labels"
                   :alt="expanded ? 'collapse' : 'expand'"
            />

            Labels
        </h2>

        <ul v-show="expanded"
            id="labels"
            ref="availableLabels"
            class="flex flex-col gap-3
                    rounded-lg
                    px-2
                    py-2"
            :aria-expanded="expanded"
        >
            <li v-for="label in labels"
                :id="label.uuid"
                :key="label.uuid"
                :ref="label.uuid"
                class="w-full"
            >
                <div v-if="editing !== label.uuid"
                     tabindex="0"
                     class="flex justify-between
                        bg-blue-600
                        text-white
                        hover:text-black
                        p-1
                        rounded-lg
                        cursor-pointer
                        focus:text-black
                        w-full
                        border-2"
                     :style="{
                        backgroundColor: label.color,
                        borderColor: label.color,
                     }"
                     @click="editing = label.uuid"
                     @keydown.space.prevent="editing = label.uuid"
                     @keydown.enter.prevent="editing = label.uuid"
                >
                    {{ label.name }}
                    <cross title="delete"
                           class="w-5 h-5 mt-1 text-gray-900 hover:text-red-800"
                           @click.prevent="deleteLabel({uuid: label.uuid})"
                    ></cross>
                </div>

                <label-form v-if="editing === label.uuid"
                            :label="label"
                            @cancelled="editing = ''"
                            @changed="saveChangesToLabel"
                            @keydown.esc.prevent = "editing = ''"
                ></label-form>
            </li>

            <li>
                <div v-if="editing !== 'new-label'"
                     @click="editing = 'new-label'"
                     class="flex items-baseline
                            border-2 border-blue-600
                            p-1
                            rounded-lg
                            hover:bg-blue-200 dark:hover:bg-blue-400
                            cursor-pointer
                            text-blue-600 dark:text-blue-300
                     "
                     tabindex="0"
                     @keydown.enter.prevent="editing = 'new-label'"
                     @keydown.space.prevent="editing = 'new-label'"
                >
                    <span class="inline-block w-4 mr-2">
                        <add-button title="Create new label"></add-button>
                    </span>
                    Create new label
                </div>

                <label-form v-if="editing === 'new-label'"
                            :label="{ color: '', name: '' }"
                            @cancelled="editing = ''"
                            @changed="saveNewLabel"
                            @keydown.esc.prevent="editing = ''"
                >
                </label-form>
            </li>
        </ul>
    </div>
</template>

<script>
import ActionTypes from "@/store/action-types";
import {
  mapGetters,
  mapState
} from 'vuex';
import LabelForm from "@/components/LabelForm";
import Cross from "@/components/ui/Cross";
import AddButton from "@/components/buttons/Add";

export default {
  components: {
    AddButton,
    Cross,
    LabelForm
  },
  data() {
    return {
      expanded: false,
      editing: '',
    }
  },
  computed: {
    ...mapGetters([
      'labelByUuid',
    ]),
    ...mapState({
      labels: state => state.settings.labels,
    })
  },

  methods: {
    saveChangesToLabel({uuid, name, color}) {
      const oldLabel = this.labelByUuid(uuid);

      if (oldLabel.name !== name) {
        this.$store.dispatch(ActionTypes.RENAME_LABEL, {uuid, name});
      }

      if (oldLabel.color !== color) {
        this.$store.dispatch(ActionTypes.CHANGE_LABEL_COLOR, {uuid, color});
      }

      this.editing = '';
    },
    saveNewLabel({ name, color }) {
      this.$store.dispatch(ActionTypes.ADD_LABEL, { name, color });

      this.editing = '';
    },
    deleteLabel({ uuid }) {
      this.$store.dispatch(ActionTypes.DELETE_LABEL, {uuid});
    }
  }
}
</script>