<template>
    <h1 class="mt-0">Add new to-do</h1>

    <new-todo @save="(uuid) => { router.push({name: 'Todo', params: { uuid }}) }"></new-todo>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useHead } from '@vueuse/head';

import NewTodo from '@/components/NewTodo';

const router = useRouter();

useHead({
  title: 'Add Todo | Cross It Off',
});
</script>
