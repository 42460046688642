<template>
    <nav class="flex gap-10 p-4">
        <router-link :to="{ name: 'About' }">About</router-link>
        <router-link :to="{ name: 'Privacy' }">Privacy</router-link>
        <router-link :to="{ name: 'Settings' }">Settings</router-link>
    </nav>

    <h1 class="mt-8">Privacy</h1>

    <p class="mt-8">
        <em class="font-bold">
            All data entered by you into this app is stored locally and securely within your browser and is not shared in any way.
        </em>
    </p>

    <p class="mt-8">
        <em class="font-bold">
            We want to process as little personal information as possible when you use our website.
        </em>

        That's why we've chosen Fathom Analytics for our website analytics, which doesn't use cookies and complies with
        the GDPR, ePrivacy (including PECR), COPPA and CCPA. Using this privacy-friendly website analytics software,
        your IP address is only briefly processed, and we (running this website) have no way of identifying you.
        As per the CCPA, your personal information is de-identified.
        You can read more about this on
        <a href="https://usefathom.com/compliance">Fathom Analytics' website</a>.
    </p>

    <p class="mt-8">
        The purpose of us using this software is to understand our website traffic in the most privacy-friendly way
        possible so that we can continually improve our website and business.
        As per the explanation, no personal data is stored over time.
    </p>
</template>

<script setup>
import { useHead } from '@vueuse/head';

useHead({
  title: 'Privacy | Cross It Off',
});
</script>