<template>
    <div v-if="!editing"
         @click="editing = editable"
         class="outline-none
                py-2 px-1 narrow:p-0
                border-2 border-transparent rounded-lg
                focus:border-gray-400 focus:dark:border-gray-500"
         :class="{ 'cursor-pointer': editable }"
         :tabindex="editable ? 0 : -1"
         @keydown.space.prevent="editing = editable && true"
         @keydown.enter.prevent="editing = editable && true"
         ref="field"
    >
        <div v-if="!description"
             class="italic text-blue-600 dark:text-blue-300 "
        >Add a description&#8230;</div>

        <div v-if="description"
             class="overflow-auto markdown"
             @click="markdownClicked"
             v-html="sanitizedMarkdown"
        >

        </div>
    </div>

    <form v-if="editing"
          @submit.prevent="save"
          @keydown.esc.prevent="editing = false; nextTick(() => field.focus())"
          class="flex flex-col
                    h-full
                    border-2 border-gray-400 dark:border-gray-500
                    rounded-lg
                    outline-4 outline-transparent
                    focus-within:border-blue-500">
        <p class="rounded-t-lg
                    m-0 p-2
                    border-inherit border-b-2"
        >
            Some <a href="https://www.markdownguide.org/cheat-sheet/">markdown</a> is allowed
        </p>
        <textarea id="description"
                  ref="textarea"
                  :value="description"
                  class="block
                            dark:bg-gray-300
                            narrow:text-xl dark:text-gray-900
                            border-inherit border-b-2
                            outline-none
                            flex-grow
                            min-h-15rem
                            p-2
                            w-full
                            overflow-y-auto"
        ></textarea>

        <div class="flex w-full mt-0 border-inherit">
            <submit-button class="flex-grow border-0 rounded-bl"></submit-button>

            <button @click="editing = false"
                    type="button"
                    class="flex-grow
                            hover:bg-blue-200 dark:hover:bg-blue-400
                            border-inherit border-l-2
                            px-2 py-1
                            rounded-br"
            >
                Cancel
            </button>
        </div>
    </form>
</template>

<script setup>
import { computed, nextTick, ref, toRefs, watch } from 'vue';

import {marked} from 'marked';
import sanitizeHtml from 'sanitize-html';

import SubmitButton from "@/components/form/SubmitButton";

const emit = defineEmits(['changed']);

const props = defineProps({
  close: {
    type: Boolean,
    required: true,
  },
  description: {
    type: String,
    required: true,
  },
  editable: {
    type: Boolean,
    default: true,
  }
});

const { close, description, editable } = toRefs(props);

const editing = ref(false);
const field = ref(null);
const textarea = ref(null);

const sanitizedMarkdown = computed(() => {
  return sanitizeHtml(marked.parse(description.value));
});

const markdownClicked = (event) => {
  if (event.target.href) {
    event.stopPropagation();
  }
};

const save = () => {
  emit('changed', textarea.value.value);
  editing.value = false;
};

watch(editing, (currentValue, oldValue) => {
  if (currentValue) {
    nextTick(() => {
      textarea.value.focus();
    })
  }
});

watch(close, (currentValue) => {
  if (currentValue) {
    editing.value = false;
  }
});
</script>