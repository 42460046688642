<template>


    <main
        class="relative grid-in-main
               min-h-full
               p-4
               overflow-x-none overflow-y-auto
               rounded-t-xl narrow:rounded-none
               bg-gray-100 dark:bg-gray-800"
    >
        <div v-if="store.getters.message"
             class="sticky
                    left-0 top-3/4
                    h-0
                    z-50"
        >
            <div class="w-full
                        p-3 py-8
                        border-4 border-blue-600 bg-blue-100
                        text-gray-900
                        rounded-xl
                        shadow-2xl
                        text-center"
                 :class="{
                    'border-red-600': store.getters.message.type === 'error',
                    'bg-red-100': store.getters.message.type === 'error',
                 }"
            >
                <p>{{ store.getters.message.message }}</p>

                <button v-for="(action, index) in store.getters.message.actions"
                        :key="index"
                        class="text-blue-600 hover:text-purple-500 underline mx-2"
                        :title="action.title"
                        @click="action.callback(); dismissMessage()"
                >
                    {{ action.text }}
                </button>

                <button @click="dismissMessage"
                        class="text-blue-600 hover:text-purple-500 underline mx-2"
                >
                    Dismiss
                </button>
            </div>
        </div>

        <router-view></router-view>
    </main>
    <my-menu class="grid-in-menu rounded-b-xl narrow:rounded-none"></my-menu>
</template>

<script setup>
import {useStore} from 'vuex';

import MyMenu from '@/components/Menu';

import ActionTypes from "@/store/action-types";

const store = useStore();

const dismissMessage = () => {
  if (store.getters.message.callback) {
    store.getters.message.callback();
  }

  store.dispatch(ActionTypes.SHIFT_MESSAGE);
};
</script>
