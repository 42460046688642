<template>
    <svg viewBox="0 0 320 320"
         xmlns="http://www.w3.org/2000/svg"
         class="stroke-current"
    >
        <title>Home</title>
        <path fill="none"
              stroke-width="20"
              stroke-linejoin="round"
              stroke-linecap="round"
              d="M 160  15
                 L 305 160
                 L 250 160
                 L 250 305
                 L  70 305
                 L  70 160
                 L  20 160
                 L  70  96
                 L  70  30
                 L 110  30
                 L 110  67
                 L 160  15"
        />
    </svg>
</template>
